import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import ReactPlayer from "react-player";
import { Row, Col, Icon, Badge, Modal, Tooltip, Spin, Pagination } from "antd";
import { videoData, icons, ColorStatus, DASHBOARD_TYPE } from "./Constant";
import { ReactComponent as New } from "../Icon/Rectangle.svg";
import { ReactComponent as ParkingRest } from "../Icon/VideoIcon/VehicleResting.svg";
import { ReactComponent as Alert } from "../Icon/VideoIcon/Alert.svg";
import ModalInfo from "./ModalInfo";
import ModalPhoneNumber from "./ModalPhoneNumber";
import {
  loadVideoAndBookmark,
  createBookmark,
  deleteBookmark,
} from "../fetchAPI/apiClient";
import ModalVideo from "./ModalVideo";
import ModalDriverNoti from "./ModalDriverNoti";

const VideoAndBookmark = forwardRef(({ type, auth, filterObj }, ref) => {
  const [visibleInfo, setVisibleInfo] = useState(false);
  const [visiblePhoneNumber, setVisiblePhoneNumber] = useState(false);
  const [initialVideoData, setInitialVideoData] = useState({
    bookmark_video_list: [],
    video_list: [],
  });
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [visibleVideo, setVisibleVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [visibleDriverNoti, setVisibleDriverNoti] = useState(false);
  const [info, setInfo] = useState({ imei: "", plateNo: "", code: "" });
  const [vehicleId, setVehicleId] = useState(null);

  useEffect(() => {}, []);

  const handleDriverNoti = () => {
    setVisibleDriverNoti(true);
  };

  const onCloseDriverNoti = () => {
    setVisibleDriverNoti(false);
  };

  const handleVisibleVideo = (url) => {
    setVisibleVideo(true);
    setVideoUrl(url);
  };

  const onCloseModalVideo = () => {
    setVisibleVideo(false);
  };

  const handleInfo = (id) => {
    setVisibleInfo(true);
    setVehicleId(id);
  };

  const onCloseInfo = () => {
    setVisibleInfo(false);
    setVehicleId(null);
  };

  const handlePhoneNumber = (info) => {
    setVisiblePhoneNumber(true);
    setInfo({
      imei: info.hardware_id,
      plateNo: info.plate_no,
      code: info.code,
    });
  };

  const onClosePhoneNumber = () => {
    setVisiblePhoneNumber(false);
    setInfo({ imei: "", plateNo: "", code: "" });
  };

  const moveToBookmark = (id) => {
    setInitialVideoData((prevData) => {
      // Find the index of the video in the video_list
      const videoIndex = prevData.video_list.findIndex(
        (video) => video.id === id
      );

      // Check if the video exists in the video_list
      if (videoIndex !== -1) {
        // Remove the video from video_list and get the video object
        const [video] = prevData.video_list.splice(videoIndex, 1);

        // Return the updated state with the video added to bookmark_video_list
        return {
          ...prevData,
          video_list: [...prevData.video_list],
          bookmark_video_list: [video, ...prevData.bookmark_video_list],
        };
      }

      // If the video is not found, return the previous state
      return prevData;
    });
    createBookmark(
      {
        user_id: auth.profile.id,
        vehicle_id: id,
      },
      (status, res) => {
        console.log(res);
      }
    );
  };

  const moveToVideoList = (id) => {
    setInitialVideoData((prevData) => {
      // Find the index of the video in the bookmark_video_list
      const videoIndex = prevData.bookmark_video_list.findIndex(
        (video) => video.id === id
      );

      // Check if the video exists in the bookmark_video_list
      if (videoIndex !== -1) {
        // Remove the video from bookmark_video_list and get the video object
        const [video] = prevData.bookmark_video_list.splice(videoIndex, 1);

        // Return the updated state with the video added to video_list
        return {
          ...prevData,
          bookmark_video_list: [...prevData.bookmark_video_list],
          video_list: [...prevData.video_list, video],
        };
      }

      // If the video is not found, return the previous state
      return prevData;
    });

    deleteBookmark(
      {
        user_id: auth.profile.id,
        vehicle_id: id,
      },
      (status, res) => {
        console.log(res);
      }
    );
  };

  const getDashboard = (payload, loading) => {
    loadVideoAndBookmark(payload, (statusCode, response) => {
      setInitialVideoData(response.data);
      setTotal(
        type === DASHBOARD_TYPE.video.type
          ? response.data.totalVideo
          : response.data.totalBookmark
      );
      loading();
    });
  };

  useImperativeHandle(ref, () => ({
    getDashboard,
    page,
    loading,
    setPage,
  }));

  return (
    <Spin spinning={loading}>
      {/* <div style={{ maxHeight: window.innerHeight - 155, overflowY: "auto" }}> */}
      <Row gutter={[16, 16]}>
        {initialVideoData.bookmark_video_list
          .slice(
            0,
            type === DASHBOARD_TYPE.video.type
              ? 4
              : initialVideoData.bookmark_video_list.length
          )
          .map((item, index) => (
            <Col span={6} key={index}>
              <div
                style={{
                  borderRadius: 8,
                  overflow: "hidden",
                  height: 300,
                  marginBottom: 5,
                }}
              >
                <ReactPlayer
                  url={item.link_video}
                  controls={true}
                  playing={item.auto_play}
                  muted={true} // Mute the video to bypass autoplay restrictions
                  width="100%"
                  height="100%"
                />
              </div>
              <Row
                gutter={[16, 16]}
                type="flex"
                justify="space-around"
                align="middle"
              >
                <Col span={16}>
                  <span style={{ verticalAlign: "middle" }}>
                    {item["vehicle_info"]["plate_no"]}{" "}
                  </span>
                  <span style={{ verticalAlign: "middle" }}>
                    <Alert fill={item.alert ? "#F53C56" : "#CBCBCB"} />
                  </span>
                </Col>
                <Col span={8} align="right">
                  <Icon
                    type="phone"
                    style={{
                      fontSize: 16,
                      color: "black",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handlePhoneNumber(item.vehicle_info);
                    }}
                  />
                  <Icon
                    type="info-circle"
                    style={{
                      fontSize: 16,
                      color: "black",
                      cursor: "pointer",
                      marginLeft: 8,
                    }}
                    onClick={() => {
                      window
                        .open(
                          `/dashboard-adas/detail/${item.id}/${filterObj.duration}/${item.vehicle_info.hardware_id}/${filterObj.type}`,
                          "_blank"
                        )
                        .focus();
                      // handleInfo(15);
                    }}
                  />{" "}
                  <Icon
                    type="star"
                    style={{
                      color: "#FFD000",
                      fontSize: 16,
                      cursor: "pointer",
                      marginLeft: 5,
                    }}
                    theme="filled"
                    onClick={() => {
                      moveToVideoList(item.id);
                    }}
                  />
                </Col>
                <Col span={17}>
                  {item.event_stat.map((evt) => (
                    <Tooltip
                      title={`${icons[evt.event_name].label}: ${
                        evt.event_count
                      }`}
                    >
                      <span style={{ marginRight: 10 }}>
                        {item.lasted_event === evt.event_type ? (
                          <Badge count={<New />} offset={[0, -8]}>
                            {icons[evt.event_name].icon({
                              fill: ColorStatus[evt.event_status]["color"],
                            })}
                          </Badge>
                        ) : (
                          icons[evt.event_name].icon({
                            fill: ColorStatus[evt.event_status]["color"],
                          })
                        )}
                      </span>
                    </Tooltip>
                  ))}
                </Col>
                <Col span={7} align="right">
                  {/* <span>
                      <ParkingRest
                        fill={"#2DCE98"}
                        style={{ verticalAlign: "top" }}
                      />{" "}
                    </span>
                    <span
                      style={{ color: "#2DCE98", verticalAlign: "textTop" }}
                    >
                      จอดพัก 30 นาที
                    </span> */}
                </Col>
              </Row>
            </Col>
          ))}
        {initialVideoData.video_list
          .slice(
            0,
            type === DASHBOARD_TYPE.video.type
              ? initialVideoData.video_list.length
              : 0
          )
          .map((item, index) => (
            <Col span={6} key={index}>
              <div
                style={{
                  borderRadius: 8,
                  overflow: "hidden",
                  height: 300,
                  marginBottom: 5,
                }}
              >
                <ReactPlayer
                  url={item.link_video}
                  width="100%"
                  height="100%"
                  controls={true}
                  playing={item.auto_play}
                />
              </div>
              <Row
                gutter={[16, 16]}
                type="flex"
                justify="space-around"
                align="middle"
              >
                <Col span={16}>
                  <span style={{ verticalAlign: "middle" }}>
                    {item["vehicle_info"]["plate_no"]}{" "}
                  </span>
                  <span style={{ verticalAlign: "middle" }}>
                    <Alert fill={item.alert ? "#F53C56" : "#CBCBCB"} />
                  </span>
                </Col>

                <Col span={8} align="right">
                  <Icon
                    type="phone"
                    style={{
                      fontSize: 16,
                      color: "black",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handlePhoneNumber(item.vehicle_info);
                    }}
                  />
                  <Icon
                    type="info-circle"
                    style={{
                      fontSize: 16,
                      color: "black",
                      cursor: "pointer",
                      marginLeft: 8,
                    }}
                    onClick={() => {
                      window
                        .open(
                          `/dashboard-adas/detail/${item.id}/${filterObj.duration}/${item.vehicle_info.hardware_id}/${filterObj.type}`,
                          "_blank"
                        )
                        .focus();
                      // handleInfo(15);
                    }}
                  />{" "}
                  <Icon
                    type="star"
                    style={{
                      fontSize: 16,
                      color: "black",
                      cursor: "pointer",
                      marginLeft: 5,
                    }}
                    onClick={() => {
                      moveToBookmark(item.id);
                    }}
                  />
                </Col>
                <Col span={17}>
                  {item.event_stat.map((evt) => (
                    <Tooltip
                      title={`${icons[evt.event_name].label}: ${
                        evt.event_count
                      }`}
                    >
                      <span style={{ marginRight: 10 }}>
                        {item.lasted_event === evt.event_type ? (
                          <Badge count={<New />} offset={[0, -8]}>
                            {icons[evt.event_name].icon({
                              fill: ColorStatus[evt.event_status]["color"],
                            })}
                          </Badge>
                        ) : (
                          icons[evt.event_name].icon({
                            fill: ColorStatus[evt.event_status]["color"],
                          })
                        )}
                      </span>
                    </Tooltip>
                  ))}
                </Col>
                <Col span={7} align="right">
                  {/* <span>
                      <ParkingRest
                        fill={"#2DCE98"}
                        style={{ verticalAlign: "top" }}
                      />{" "}
                    </span>
                    <span
                      style={{ color: "#2DCE98", verticalAlign: "textTop" }}
                    >
                      จอดพัก 30 นาที
                    </span> */}
                </Col>
              </Row>
            </Col>
          ))}
        {type === DASHBOARD_TYPE.video.type ? (
          <Col span={24} align="right">
            <Pagination
              size="small"
              style={{ paddingTop: 10 }}
              defaultCurrent={page}
              current={page}
              total={total}
              defaultPageSize={12}
              onChange={(page, pageSize) => {
                getDashboard({
                  date_start: filterObj.startDate,
                  date_end: filterObj.endDate,
                  event: filterObj.type,
                  time_range: filterObj.duration,
                  vehicle_group: filterObj.vehicle_group,
                  page,
                  user_id: auth.profile.id,
                  vehicle_visibility: auth.profile.vehicle_visibility,
                  company_id: auth.profile.company_id,
                });
                setPage(page);
              }}
            />
          </Col>
        ) : null}

        <ModalInfo
          visibleInfo={visibleInfo}
          vehicleId={vehicleId}
          onCloseInfo={onCloseInfo}
          handleVisibleVideo={handleVisibleVideo}
          handleDriverNoti={handleDriverNoti}
        />
        <ModalPhoneNumber
          visiblePhoneNumber={visiblePhoneNumber}
          info={info}
          onClosePhoneNumber={onClosePhoneNumber}
        />
        <ModalVideo
          videoUrl={videoUrl}
          visibleVideo={visibleVideo}
          onCloseModalVideo={onCloseModalVideo}
        />
        <ModalDriverNoti
          visibleDriverNoti={visibleDriverNoti}
          onCloseDriverNoti={onCloseDriverNoti}
        />
      </Row>
      {/* </div> */}
    </Spin>
  );
});

export default VideoAndBookmark;
