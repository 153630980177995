import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Spin, Button, Modal } from "antd";
import ReactPlayer from "react-player";
import moment from "moment";
import Filter from "./Filter";
import LangContext from "modules/shared/context/langContext";
import * as actions from "../actions";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "./../../../shared/components/Breadcrumb";
import axios from "axios";
import { fix_minutes, fix_hours } from "../constants/Constants";

class Index extends Component {
  state = {
    filedownloading: false,
    idprocess: "web" + new Date().getTime(),
    idconnect: "",
    height: window.innerHeight - 250,
    customTime: { marker: moment().format("YYYY-MM-DD") + " 12:00:00" },
    items: [],
    date_min: moment().format("YYYY-MM-DD") + " 00:00:00",
    date_max: moment().format("YYYY-MM-DD") + " 23:59:59",
    time_cursor: "",
    datetime_select: "",
    link_streaming: null,
    camera_potion: "",
    playedSeconds: 0,
    duration: 0,
    playing: false,
    visible_button_refresh: false,
    check_load_vihicle: false,
    change_camera: false,
    time_playing: "",
    hourActive: null,
    listOfMinutes: [],
    minuteActive: null,
    date_select: "",
    informationSelectionVehicle: {
      show: false,
      vehiclePlantNo: "",
      date: "",
      cameraPosition: "",
      hourDuration: "",
      minutesDuration: "",
    },
    webSocketLoading: false,
    deviceProcess: null
  };

  ws = new WebSocket(
    `wss://${process.env.REACT_APP_WEBSOCKET}/ws?idprocess=` +
    this.state.idprocess
  );

  getVehicleList = () => {
    const url =
      `https://${process.env.REACT_APP_WEBSOCKET}/getAllConnection?idprocess=` +
      this.state.idprocess;
    //const url = "http://localhost:8001/getAllConnection?idprocess=web1667466579431"
    axios.get(url).then((res) => {
      if (res.data.message !== "") {
        this.loadVehicle(res.data.message.split(","));
      }
    });
  };

  componentDidMount() {
    this.ws.onopen = () => {
      // on connecting, do nothing but log it to the console
      // console.log("connected");
      this.getVehicleList();
    };

    this.ws.onmessage = (evt) => {
      // listen to data sent from the websocket server
      // const message = JSON.parse(evt.data)
      var messages = evt.data.split("\n");

      for (var i = 0; i < messages.length; i++) {
        let obj = JSON.parse(messages[i]);

        if (obj.Idprocess == "" && obj.Idconnect == "") {
          // console.log("allconneciton", obj.Allconnection)
          // if (this.state.check_load_vihicle === false) {
          //   this.loadVehicle(obj.Allconnection);
          //   this.setState({ check_load_vihicle: true });
          // }
        } else {
          console.log("receive", JSON.parse(obj.Data))
          let data = JSON.parse(obj.Data);
          if (data.type == "get_list_playback_video") {
            let newlistFiles = [];
            let d = data.duration_time;
            let start_time = "";
            // console.log("length:", d.length);
            for (let i = 0; i < d.length; i++) {
              if (i == 0)
                start_time = String(d[i].date + " " + d[i].start_time); //" 12:00:00"
              newlistFiles.push({
                id: i + 1,
                start: String(d[i].date + " " + d[i].start_time),
                end: String(d[i].date + " " + d[i].end_time),
              });
            }
            // console.log("customTime:", start_time);
            // console.log("items:", newlistFiles);
            if (this.state.change_camera === true) {
              this.setState({
                items: newlistFiles,
                // customTime: { marker: start_time },
                date_min: moment(start_time).format("YYYY-MM-DD") + " 00:00:00",
                date_max: moment(start_time).format("YYYY-MM-DD") + " 23:59:59",
              });
            } else {
              this.setState({
                filedownloading: false,
                items: newlistFiles,
                customTime: { marker: start_time },
                date_min: moment(start_time).format("YYYY-MM-DD") + " 00:00:00",
                date_max: moment(start_time).format("YYYY-MM-DD") + " 23:59:59",
              });
            }

            if (this.state.change_camera === true) {
              let timeCurrent = moment(this.state.datetime_select)
                .add(this.state.playedSeconds, "seconds")
                .format("YYYY-MM-DD HH:mm:ss");
              // console.log(
              //   "timecurChangeCamera",
              //   this.state.datetime_select,
              //   timeCurrent
              // );
              this.setState({
                change_camera: false,
                customTime: { marker: timeCurrent },
                datetime_select: timeCurrent,
              });
              const data = JSON.stringify({
                type: "playback_video",
                camera_source: this.state.camera_potion,
                datetime_select: timeCurrent,
                user_id: this.props.profile.id.toString(),
                id_process: this.state.idprocess
              });
              const message = {
                Idconnect: this.state.idconnect,
                Idprocess: this.state.idprocess,
                Data: data,
              };
              console.log("change camera:", JSON.stringify(message));
              this.ws.send(JSON.stringify(message));
            }
          } else if (data.type == "playback_video") {
            var v = moment().format("YYYYMMDDh:mm:ss.SSS");
            // console.log("link_streaming:", data.link_streaming, data);
            if (
              data.link_streaming != "No video streaming" &&
              data.link_streaming != undefined
            ) {

              let url = data.link_streaming.includes("nimble") ? "https://nimble.terminusfleet.com" : "https://stream.terminusfleet.com"
              this.setState({
                filedownloading: false,
                idconnect: obj.Idprocess,
                link_streaming:
                  url + "/hnt/" + obj.Idprocess + "/playlist.m3u8?" + v, //data.link_streaming,
                webSocketLoading: false,
              });
            } else {
              if (data.link_streaming != undefined) {
                this.setState({
                  filedownloading: false,
                  playing: false,
                  link_streaming: null,
                });
                // this.fileNotStream();
              } else {
                // this.setState({
                //     link_streaming:
                //   "https://stream.terminusfleet.com/hnt/" +
                //   obj.Idprocess +
                //   "/playlist.m3u8?" +v,
                //   playing: false,
                //   });
              }
            }
          }
        }
      }

      //this.loadDataFromIncidentDashboard();
    };

    this.ws.onclose = () => {
      // automatically try to reconnect on connection loss
      // console.log("disconnected");
    };

    this.interval = setInterval(() => {
      this.handleCheckProcess()
    }, 1000 * 15);
  }

  fileNotStream = () => {
    Modal.warning({
      content: (
        <LangContext.Consumer>
          {(i18n) => i18n.n.nofilevideo}
        </LangContext.Consumer>
      ),
    });
  };

  filterHoneytoastplayback = (values) => {
    this.setState(
      {
        link_streaming: null,
        hourActive: null,
        listOfMinutes: [],
        minuteActive: null,
        date_select: "",
        informationSelectionVehicle: {
          show: false,
          vehiclePlantNo: "",
          date: "",
          cameraPosition: "",
          hourDuration: "",
          minutesDuration: "",
        },
        webSocketLoading: false,
        visible_button_refresh: false,
      },
      () => {
        const { imei, date, camera_position, plate_no } = values;
        const date_select = moment(date).format("YYYY-MM-DD");
        let change_camera = false;
        if (this.state.camera_potion !== "" && this.state.idconnect === imei) {
          //เปลี่ยนกล้องทะเบียนเดิม
          if (this.state.camera_potion !== camera_position) {
            change_camera = true;
          }
        }

        this.setState({
          filedownloading: true,
          idconnect: imei,
          camera_potion: camera_position,
          change_camera,
          playing: false,
          date_select: date_select,
          informationSelectionVehicle: {
            show: false,
            vehiclePlantNo: plate_no,
            date: date_select,
            cameraPosition: camera_position,
            hourDuration: "",
            minutesDuration: "",
          },
        });
        const data = JSON.stringify({
          type: "get_list_playback_video",
          date_select: date_select,
          camera_source: camera_position,
        });
        const message = {
          Idconnect: imei,
          Idprocess: this.state.idprocess,
          Data: data,
        };
        console.log("send message", message)
        this.ws.send(JSON.stringify(message));
      }
    );
  };

  loadVehicle = (allimei) => {
    const { loadVehicle } = this.props;
    loadVehicle(allimei);
  };

  loadVideo = (e, file_name, imei) => {
    e.preventDefault();
    // console.log("filename", file_name, imei);
    this.setState({ filedownloading: true });
    const data = JSON.stringify({ type: "download_video", name: file_name });
    const message = {
      Idconnect: imei,
      Idprocess: this.state.idprocess,
      Data: data,
    };
    this.ws.send(JSON.stringify(message));
  };

  handleDuration = (duration) => {
    // console.log("onDuration", duration);
    this.setState({ duration });
  };

  // handleSeekChange = e => {
  //   console.log('seek:', parseFloat(e.target.value))
  //   this.setState({ played: parseFloat(e.target.value) })
  // }

  handleProgress = (state) => {
    // console.log("onProgress", state);
    //console.log("playing:", this.state.playing)
    let timeCurrent = moment(this.state.datetime_select)
      .add(state.playedSeconds, "seconds")
      .format("HH:mm:ss");
    this.setState({
      playedSeconds: parseInt(state.playedSeconds),
      time_playing: timeCurrent,
    });
  };

  onErrorPlayer = (e) => {
    // console.log("error", e);
    let timeCurrent = moment(this.state.datetime_select)
      .add(this.state.playedSeconds, "seconds")
      .format("YYYY-MM-DD HH:mm:ss");
    // console.log("timecur", timeCurrent);
    let timePlaying = new Date(timeCurrent);
    // console.log("timeplay", timePlaying);
    let items = this.state.items;
    //if(e==="hlsError")
    //{
    // console.log("Have error",this.state.playing)
    // var v = moment().format('YYYYMMDDh:mm:ss.SSS')
    // this.setState({
    //     link_streaming:
    //     "https://stream.terminusfleet.com/hnt/" +
    //     this.state.idconnect +
    //     "/playlist.m3u8?" +v
    //   });
    // }else{
    if (this.state.playing == true) {
      for (let i = 0; i < items.length; i++) {
        let timeStart = new Date(items[i].start);
        let timeEnd = new Date(items[i].end);
        //console.log("item:", timeStart, timeEnd)
        if (
          timeStart.getTime() <= timePlaying.getTime() &&
          timePlaying.getTime() <= timeEnd.getTime()
        ) {
          // console.log("timeEnd:", timeEnd);

          let timePlayingM = moment(timeCurrent);
          let timeEndM = moment(items[i].end);
          let diffSeconds = timeEndM.diff(timePlayingM, "seconds");
          if (diffSeconds < 10) {
            //this.setState({ playing: false })
            // console.log("diffSeconds :", diffSeconds);

            //////เล่นไฟล์ต่อไป/////////
            if (i !== items.length - 1) {
              this.setState({
                filedownloading: true,
                playing: false,
                visible_button_refresh: false,
                datetime_select: items[i + 1].start,
              });
              const data = JSON.stringify({
                type: "playback_video",
                camera_source: this.state.camera_potion,
                datetime_select: items[i + 1].start,
                user_id: this.props.profile.id.toString(),
                id_process: this.state.idprocess
              });
              const message = {
                Idconnect: this.state.idconnect,
                Idprocess: this.state.idprocess,
                Data: data,
              };
              // console.log(JSON.stringify(message));
              this.ws.send(JSON.stringify(message));
            }
          }
        }
      } //end for
    } //end if
    //}
  };

  handleEnded = () => {
    // console.log("onEnded");
  };
  // clickHandler = (props) => {
  //   const { group } = props
  //   console.log("ddd", props)
  //   this.setState({
  //     customTime: { marker: '2020-03-6 15:40:14' }
  //   });

  // }

  timechangeHandler = (props) => {
    const { group } = props;
    // console.log("timechange", moment(props.time).format("H:mm:ss"));
    this.setState({
      time_cursor: moment(props.time).format("H:mm:ss"),
      customTime: { marker: moment(props.time).format("YYYY-MM-DD H:mm:ss") },
    });
  };

  timechangedHandler = (props) => {
    const { group } = props;

    // console.log("timechanged", props);
    this.setState({
      filedownloading: true,
      visible_button_refresh: false,
      datetime_select: moment(props.time).format("YYYY-MM-DD HH:mm:ss"),
    });
    const data = JSON.stringify({
      type: "playback_video",
      camera_source: this.state.camera_potion,
      datetime_select: moment(props.time).format("YYYY-MM-DD HH:mm:ss"),
      user_id: this.props.profile.id.toString(),
      id_process: this.state.idprocess
    });
    const message = {
      Idconnect: this.state.idconnect,
      Idprocess: this.state.idprocess,
      Data: data,
    };
    console.log("timechangedHandler", message);
    this.ws.send(JSON.stringify(message));
  };

  componentWillUnmount() {
    this.ws.close();
    clearInterval(this.interval);
  }

  // function select hour

  handleSelectHour = (item) => {
    // let gethour = fix_hours.indexOf({ start_hour: item });
    // console.log("handleSelectHour", fix_hours, item, gethour);
    this.setState((prevState) => ({
      informationSelectionVehicle: {
        ...prevState.informationSelectionVehicle,
        hourDuration: `${fix_hours[item].start_hour}:00 - ${fix_hours[item].end_hour}:00 น.`,
        minutesDuration: "",
        show: false,
      },
    }));
    this.setState(
      {
        hourActive: item,
        minuteActive: null,
        link_streaming: null,
        visible_button_refresh: false,
      },
      () => {
        const hours2 = this.state.items.filter(
          (item) => moment(item.start).hour() === this.state.hourActive
        );

        const minutes2 = hours2.map((item) => moment(item.start).minutes());
        const minutes3 = minutes2.filter(
          (value, index, array) => array.indexOf(value) === index
        );

        this.setState({
          listOfMinutes: minutes3,
        });
      }
    );
  };

  handleSelectMinutes = (index) => {
    this.setState((prevState) => ({
      informationSelectionVehicle: {
        ...prevState.informationSelectionVehicle,
        minutesDuration: `${fix_hours[this.state.hourActive].start_hour}:${fix_minutes[index].start
          } - ${fix_minutes[index].end == "00"
            ? `${fix_hours[this.state.hourActive].end_hour}:${fix_minutes[index].end
            } น.`
            : `${fix_hours[this.state.hourActive].start_hour}:${fix_minutes[index].end
            } น.`
          }`,
        show: true,
      },
    }));
    this.setState(
      {
        minuteActive: index,
        webSocketLoading: true,
        visible_button_refresh: false,
      },
      () => {
        const hours2 = this.state.items.filter(
          (item) => moment(item.start).hour() === this.state.hourActive
        );
        const minutes2 = hours2.map((item) => moment(item.start).minutes());
        const seconds = hours2.map((item) => moment(item.start).seconds());

        let getIndex = minutes2.indexOf(index);
        let getSecond = fix_minutes[seconds[getIndex]].start;

        let dateTime = `${this.state.date_select} ${this.state.hourActive}:${this.state.minuteActive}:${getSecond}`;

        const data = JSON.stringify({
          type: "playback_video",
          camera_source: this.state.camera_potion,
          datetime_select: moment(dateTime).format("YYYY-MM-DD HH:mm:ss"),
          user_id: this.props.profile.id.toString(),
          id_process: this.state.idprocess
        });
        const message = {
          Idconnect: this.state.idconnect,
          Idprocess: this.state.idprocess,
          Data: data,
        };
        console.log("handleSelectMinutes", message);
        this.ws.send(JSON.stringify(message));
      }
    );
  };

  handleCheckProcess = async () => {
    if (this.state.idconnect === "") {
      return
    }

    try {
      const allconnect = await axios.get(`https://${process.env.REACT_APP_WEBSOCKET}/getDeviceProcessByImei?imei=${this.state.idconnect}`)
      const { cntDownload, cntLivestream, cntPlayback } = allconnect.data
      let total = cntDownload + cntLivestream + cntPlayback
      let result = allconnect.data
      if (total === 0) {
        result = null
      }
      this.setState({
        deviceProcess: result
      })
    } catch (error) {

    }
  }

  render() {
    // console.log("new items", this.state.items);

    const hours = this.state.items.reduce((hour, data) => {
      const h = moment(data.start).hour();
      if (!hour.find((item) => item == h)) {
        hour.push(h);
      }

      return hour;
    }, []);

    let uniqueChars = [...new Set(hours)];

    return (
      <div>
        <GeneralStyledContent>
          <Spin spinning={this.state.filedownloading}>
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Breadcrumb
                  match={this.props.match}
                  style={{ margin: "0px 0px 14px 10px" }}
                />
              </Col>
              <Col span={24} align="center">
                <Filter
                  OnClose={this.onCloseSearch}
                  onSubmit={this.filterHoneytoastplayback}
                  vehicleLists={this.props.honeytoastplayback.vehicleLists}
                  filter={this.state.filter}
                  getVehicleList={this.getVehicleList}
                  deviceProcess={this.state.deviceProcess}
                />
              </Col>
              {/* hour */}
              <Col
                span={3}
                style={{
                  borderTop: "1px solid #bab9c2",
                  borderRight: "1px solid #bab9c2",
                  // borderBottom: "1px solid #bab9c2",
                }}
              >
                <div style={{ margin: 10 }}>
                  <div style={{ fontSize: 16, marginBottom: 10 }}>
                    เลือกชั่วโมง
                  </div>
                  <div
                    style={{
                      height: window.innerHeight - 240,
                      overflowY: "scroll",
                      paddingRight: 15,
                    }}
                  >
                    {uniqueChars.map((item, index) => (
                      <Button
                        key={index}
                        style={{
                          width: "100%",
                          borderColor:
                            item == this.state.hourActive
                              ? "#68ABF2"
                              : "#DCE0E4",
                          margin: "10px 0px",
                          height: 40,
                        }}
                        onClick={() => this.handleSelectHour(item)}
                      >
                        <label
                          style={{
                            color:
                              item == this.state.hourActive
                                ? "#68ABF2"
                                : "#939eaa",
                            cursor: "pointer",
                          }}
                        >
                          {fix_hours[item].start_hour}:00 -{" "}
                          {fix_hours[item].end_hour}:00 น.
                        </label>
                      </Button>
                    ))}
                  </div>
                </div>
              </Col>

              {/* minutes */}
              <Col
                span={7}
                style={{
                  borderTop: "1px solid #bab9c2",
                  borderRight: "1px solid #bab9c2",
                  // borderBottom: "1px solid #bab9c2",
                }}
              >
                <div style={{ margin: 10 }}>
                  <div
                    style={{ fontSize: 16, marginBottom: 10, paddingLeft: 15 }}
                  >
                    เลือกนาที
                  </div>
                  <div
                    style={{
                      height: window.innerHeight - 240,
                      overflowY: "scroll",
                      paddingRight: 15,
                      paddingLeft: 15,
                    }}
                  >
                    <Row gutter={[8, 0]}>
                      {this.state.listOfMinutes.length != 0 &&
                        fix_minutes.map((item, index) => (
                          <Col span={8}>
                            <Button
                              key={index}
                              style={{
                                width: "100%",
                                borderColor:
                                  index == this.state.minuteActive
                                    ? "#68ABF2"
                                    : "#DCE0E4",
                                margin: "10px 0px",
                                color:
                                  index == this.state.minuteActive
                                    ? "#68ABF2"
                                    : "#939eaa",
                                height: 40,
                                backgroundColor: this.state.listOfMinutes.includes(
                                  index
                                )
                                  ? "white"
                                  : "#DCE0E4",
                              }}
                              disabled={
                                this.state.listOfMinutes.includes(index)
                                  ? false
                                  : true
                              }
                              onClick={() => this.handleSelectMinutes(index)}
                            >
                              {fix_hours[this.state.hourActive].start_hour}:
                              {fix_minutes[index].start} -{" "}
                              {fix_minutes[index].end == "00"
                                ? `${fix_hours[this.state.hourActive].end_hour
                                }:${fix_minutes[index].end}${" "}
                              น.`
                                : `${fix_hours[this.state.hourActive].start_hour
                                }:${fix_minutes[index].end}${" "}
                              น.`}
                            </Button>
                          </Col>
                        ))}
                    </Row>
                  </div>
                </div>
              </Col>

              {/* ReactPlayer */}
              <Col
                span={14}
                style={{
                  borderTop: "1px solid #bab9c2",
                  // borderBottom: "1px solid #bab9c2",
                }}
                align="center"
              >
                <Spin spinning={this.state.webSocketLoading}>
                  <Row gutter={[8, 8]}>
                    <Col span={20}>
                      <div style={{ margin: 10 }}>
                        <div
                          style={{
                            fontSize: 12,
                            marginBottom: 20,
                            paddingLeft: 15,
                            textAlign: "left",
                          }}
                        >
                          {this.state.informationSelectionVehicle.show && (
                            <LangContext.Consumer>
                              {(i18n) => (
                                <>
                                  {this.state.informationSelectionVehicle
                                    .vehiclePlantNo + ", "}
                                  {this.state.informationSelectionVehicle
                                    .cameraPosition == "front" &&
                                    i18n.f.front + ", "}
                                  {this.state.informationSelectionVehicle
                                    .cameraPosition == "back" &&
                                    i18n.c.camera_back + ", "}
                                  {this.state.informationSelectionVehicle
                                    .cameraPosition == "extra" &&
                                    i18n.c.camera_extra + ", "}
                                  {this.state.informationSelectionVehicle.date +
                                    ", "}
                                  {this.state.informationSelectionVehicle
                                    .hourDuration + ", "}{" "}
                                  {
                                    this.state.informationSelectionVehicle
                                      .minutesDuration
                                  }
                                </>
                              )}
                            </LangContext.Consumer>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col span={4} align="right">
                      <div style={{ paddingRight: 20, paddingTop: 5 }}>
                        {this.state.visible_button_refresh == true ? (
                          <Button
                            type="primary"
                            onClick={() => this.p.seekTo(0)}
                          >
                            Refresh Stream
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                  </Row>

                  <div>
                    {this.state.informationSelectionVehicle.show && (
                      <ReactPlayer
                        url={this.state.link_streaming}
                        width="96%"
                        height="95%"
                        controls={true}
                        ref={(p) => {
                          this.p = p;
                        }}
                        //light="true"
                        // style={{ borderStyle: "solid" }}
                        onReady={() => this.setState({ playing: true })}
                        onStart={() =>
                          this.setState({ visible_button_refresh: true })
                        }
                        onSeek={(e) => {
                          // console.log("onSeek", e)
                        }}
                        onEnded={() => {
                          // console.log("end")
                        }}
                        onDuration={this.handleDuration}
                        onProgress={this.handleProgress}
                        onBuffer={() => {
                          // console.log("onBuffer")
                        }}
                        onError={(e) => this.onErrorPlayer(e)}
                        allowFullScreen
                        playing={this.state.playing}
                      />
                    )}
                  </div>
                </Spin>
              </Col>
            </Row>
            {/*
            <Row style={{ paddingTop: "10px" }}>
              <Timeline
                options={options}
                items={this.state.items}
                customTimes={this.state.customTime}
                timechangeHandler={this.timechangeHandler}
                timechangedHandler={this.timechangedHandler}
              />
            </Row>
            <Row>
              <Col
                span={24}
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "red",
                  fontSize: "18px",
                }}
              >
                <span>
                  เวลาที่เลือก : {this.state.time_cursor}(เวลาที่กำลังเล่น :{" "}
                  {this.state.time_playing})
                </span>
              </Col>
            </Row> */}
          </Spin>
        </GeneralStyledContent>
      </div>
    );
  }
}

const mapStateToProps = ({ honeytoastplayback, auth: { profile } }) => ({
  honeytoastplayback,
  profile,
});

const mapDispatchToProps = {
  loadVehicle: actions.loadVehicle.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
