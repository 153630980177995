import React, { Component } from "react";
import { GeneralStyledContent } from "styled/common-styled";
import Breadcrumb from "modules/shared/components/Breadcrumb.js";
import { connect } from "react-redux";

import { Row, Col, Tabs, Spin, message } from "antd";

import LineToken from "modules/setting/policynew/components/Form/SettingNotification/LineToken";
import PolicyHasUser from "modules/setting/policynew/components/Form/SettingNotification/PolicyHasUser";

import {
  loadVehicleGroup,
  loadVehicle,
  loadPolicyNoti,
  updatePolicyNoti,
  createAxios,
} from "modules/setting/policynew/fetchAPI/apiClient.js";

const { TabPane } = Tabs;

class EditNoti extends Component {
  state = {
    loading: false,
    current: 0,
    vehicleList: [],
    vehicleGroup: [],
    targetKeys: [],
    lineToken: [],
    vehicleSelected: [],
    users: [],
  };

  handeleBackward = () => {
    this.props.history.goBack();
  };

  handleSave = (type, values) => {
    this.setState({ loading: true });
    updatePolicyNoti(
      this.props.match.params.id,
      { type, values },
      (statusCode, msg) => {
        if (statusCode !== 200) {
          message.error(msg);
          this.setState({ loading: false });
          return;
        }

        message.success("บันทึกสำเร็จ");
        this.handeleBackward();
      }
    );
  };

  handleLoadData = () => {
    this.setState({ loading: true });
    loadVehicleGroup(
      { company_id: this.props.auth.profile.company_id },
      (statusCode, response) => {
        this.setState({ vehicleGroup: response.data.data });
      }
    );

    loadVehicle(
      { company_id: this.props.auth.profile.company_id },
      (statusCode, response) => {
        for (let i = 0; i < response.data.data.length; i++) {
          response.data.data[i].key = response.data.data[i]["id"];
          response.data.data[i].duplicate = false;
          delete response.data.data[i].id;
        }

        this.setState({ vehicleList: response.data.data, current: 1 });
      }
    );

    loadPolicyNoti(this.props.match.params.id, (statusCode, response) => {
      const { all_vehicles, users, line_token } = response.data.data;
      this.setState({
        targetKeys: all_vehicles,
        lineToken: line_token,
        vehicleSelected: all_vehicles,
        users: users,
        current: 2,
        loading: false,
      });
    });
  };

  componentDidMount() {
    createAxios(this.props.auth.accessToken);
    this.handleLoadData();
  }

  render() {
    return (
      <GeneralStyledContent>
        <Spin spinning={this.state.loading}>
          <Row type="flex" justify="center">
            <Col span={22}>
              <Breadcrumb
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
            <Col span={24}>
              <Tabs defaultActiveKey="1" onChange={(key) => {}}>
                {/* <TabPane tab="Line" key="1">
                  <LineToken
                    current={this.state.current}
                    prev={this.handeleBackward}
                    vehicleList={this.state.vehicleList}
                    vehicleGroup={this.state.vehicleGroup}
                    targetKeys={this.state.targetKeys}
                    createPolicy={(lineToken) => {
                      this.handleSave("line_token", lineToken.setting);
                    }}
                    dataFromEdit={this.state.lineToken}
                    type={"edit"}
                    vehicleSelected={this.state.vehicleSelected}
                    profile={this.props.auth.profile}
                  />
                </TabPane> */}
                <TabPane tab="User" key="2">
                  <PolicyHasUser
                    current={this.state.current}
                    prev={this.handeleBackward}
                    createPolicy={(users) => {
                      this.handleSave("users", users);
                    }}
                    type={"edit"}
                    userSelected={this.state.users}
                    profile={this.props.auth.profile}
                  />
                </TabPane>
                {/* <TabPane tab="Email" key="3" disabled>
                  Content of Tab Pane 2
                </TabPane>
                <TabPane tab="Website" key="4" disabled>
                  Content of Tab Pane 3
                </TabPane>
                <TabPane tab="Incident Dashboard" key="5" disabled>
                  Content of Tab Pane 3
                </TabPane> */}
              </Tabs>
            </Col>
          </Row>
        </Spin>
      </GeneralStyledContent>
    );
  }
}

export default connect(({ auth }) => ({ auth }), {})(EditNoti);
