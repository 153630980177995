import React from "react";
import { ReactComponent as Feature } from "./Dashboard.svg";
import { ReactComponent as NewsPaper } from "./Newspaper.svg";
import { ReactComponent as FAQ } from "./FAQ.svg";

const Icons = {
  2: {
    label: "ฟีเจอร์",
    icon: () => <Feature />,
  },
  1: {
    label: "ข่าวสาร",
    icon: () => <NewsPaper />,
  },
  3: {
    label: "FAQ",
    icon: () => <FAQ />,
  },
};

export { Icons };
