const Icons = {
  2: {
    label: "Feature",
  },
  1: {
    label: "General",
  },
  3: {
    label: "QA",
  },
};

export { Icons };
