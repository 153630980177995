import React, { useEffect, useState } from "react";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import { Formik } from "formik";
import * as yup from "yup";
import { Form, Row, Col, Input, Select, Button, Switch } from "antd";
import LangContext from "modules/shared/context/langContext";
import RichText from "./Richtext";
import { getNewsCategory } from "../fetchApi/apiClient";
const FormItem = Form.Item;
const Option = Select.Option;

const FormNews = ({ initialValues, readOnly, onSubmit }) => {
  const [newsCategory, setNewsCategory] = useState([]);

  useEffect(() => {
    getNewsCategory({}, (status, res) => {
      setNewsCategory(res.data.data);
    });
  }, []);

  useEffect(() => {
    console.log("initialValues", initialValues);
  }, [initialValues]);

  const handleChangeSelect = (value, setFieldValue) => {
    console.log("🚀 ~ handleChangeSelect ~ value:", value);
    setFieldValue("newsCatagory", value);
  };

  const onChangePublish = (val, setFieldValue) => {
    setFieldValue("isPublish", val);
  };

  return (
    <LangContext.Consumer>
      {(i18n) => (
        <GeneralStyledContent>
          <div
            style={{
              border: "1px solid gray",
              borderRadius: 8,
              padding: 20,
              margin: 10,
            }}
          >
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={yup.object().shape({
                titleName: yup.string().required("กรุณากรอกชื่อหัวข้อ"),
                newsCatagory: yup.string().required("กรุณาเลือกหมวดข่าวสาร"),
                content: yup
                  .string()
                  .test("is-not-empty-html", "กรุณากรอกคำอธิบาย", (value) => {
                    // Check if the content contains visible text, excluding HTML tags
                    const strippedContent = value
                      .replace(/<[^>]*>/g, "")
                      .trim();
                    return strippedContent.length > 0; // Only valid if there is non-whitespace content
                  }),
              })}
              onSubmit={(values) => {
                onSubmit(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit} layout="vertical">
                  <Row gutter={[12, 12]}>
                    <Col span={24}>
                      <label>{i18n.d.detail}</label>
                    </Col>

                    {/* Title Field */}
                    <Col span={24}>
                      <FormItem
                        label={i18n.t.titleName}
                        required
                        validateStatus={
                          touched.titleName && errors.titleName && "error"
                        }
                        help={touched.titleName && errors.titleName}
                      >
                        <Input
                          readOnly={readOnly}
                          name="titleName"
                          value={values.titleName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={i18n.f.fillInfomation}
                          style={{ width: 500 }}
                        />
                      </FormItem>
                    </Col>

                    {/* Category Field */}
                    <Col span={24}>
                      <FormItem
                        label={i18n.newsManuals.newsCategory}
                        required
                        validateStatus={
                          touched.newsCatagory && errors.newsCatagory && "error"
                        }
                        help={touched.newsCatagory && errors.newsCatagory}
                      >
                        <Select
                          disabled={readOnly}
                          value={values.newsCatagory}
                          style={{ width: 500 }}
                          onChange={(val) =>
                            handleChangeSelect(val, setFieldValue)
                          }
                        >
                          {newsCategory.map((item) => (
                            <Option value={item.id.toString()}>
                              {i18n.newsManuals[item.name]}
                            </Option>
                          ))}
                        </Select>
                      </FormItem>
                    </Col>

                    {/* Content Field with Slate */}
                    <Col span={24}>
                      <FormItem
                        label={i18n.c.content}
                        required
                        validateStatus={
                          touched.content && errors.content && "error"
                        }
                        help={touched.content && errors.content}
                      >
                        <div
                          style={{
                            border: "1px solid",
                            borderColor:
                              touched.content && errors.content
                                ? "red"
                                : "#d9d9d9",
                            borderRadius: 4,
                            padding: 8,
                            width: "100%",
                            minHeight: 150,
                          }}
                        >
                          {values.content && (
                            <RichText
                              description={values.content}
                              disabled={readOnly}
                              setDescription={(val) => {
                                setFieldValue("content", val);
                              }}
                            />
                          )}

                          {!values.content && !false && (
                            <RichText
                              description={values.content}
                              disabled={readOnly}
                              setDescription={(val) => {
                                setFieldValue("content", val);
                              }}
                            />
                          )}
                        </div>
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <Switch
                        disabled={readOnly}
                        checkedChildren="Publish"
                        unCheckedChildren="Publish"
                        checked={values.isPublish}
                        onChange={(val) => onChangePublish(val, setFieldValue)}
                      />
                    </Col>
                    {/* Submit Button */}
                    <Col span={12} style={{ textAlign: "right" }}>
                      <Button
                        onClick={() => {
                          window.location.replace("/news-manuals");
                        }}
                      >
                        {i18n.b.back}
                      </Button>
                      {!readOnly && (
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{ marginLeft: 10 }}
                        >
                          {i18n.s.submit}
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </GeneralStyledContent>
      )}
    </LangContext.Consumer>
  );
};

export default FormNews;
