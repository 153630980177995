import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import LangContext, { i18n } from "modules/shared/context/langContext";
import {
  Drawer,
  Dropdown,
  List,
  Card,
  Button,
  Row,
  Col,
  Input,
  Icon,
  Switch,
  Divider,
  Spin,
  Collapse,
  Tooltip,
  Tabs,
} from "antd";
import GridLayout from "react-grid-layout";
import "../../../../../node_modules/react-grid-layout/css/styles.css";
import "../../../../../node_modules/react-resizable/css/styles.css";

import {
  changeConfigHomeDashboardLayout,
  changeLocalStorageConfigHomeDashboardLayout,
} from "../../../auth/actions";
import { AuthorizeWidgetComponent } from "./AuthorizeWidgetComponent";
import { SpeedOverlimitWidget } from "../widgets/SpeedOverLimitWidget";
import { VehicleStatusPieChartWidget } from "../widgets/VehicleStatusPieChartWidget";
import { VehicleStatusWidget } from "../widgets/VehicleStatusWidget";
import { DriverSpeedOverLimitWidget } from "../widgets/DriverSpeedOverLimitWidget";
import { SpeedOverLimitByVehicleGroupWidget } from "../widgets/SpeedOverLimitByVehicleGroupWidget";
import { DrivingLicenseExpireWidget } from "../widgets/DrivingLicenseExpireWidget";
import News from "./News";

const { TabPane } = Tabs;

export class Index extends Component {
  state = {
    visible: false,
    layout: [],
    vehicleStatusCoDataUsingComponentList: [
      "VehicleRunningWidget",
      "VehicleStopWithEngineOffWidget",
      "VehicleStopWithEngineOnWidget",
      "VehicleConnectionErrorWidget",
      "VehicleStatusPieChartWidget",
    ],
    vehicleStatusPieData: [],
    running: 0,
    stop_with_engine_off: 0,
    stop_with_engine_on: 0,
    connection_error: 0,
    tabId: "1",
  };

  listAllofWidget = [
    {
      id: "VehicleRunningWidget",
      layout: {
        i: "VehicleRunningWidget",
        x: 13,
        y: 0,
        w: 3,
        h: 3,
        minW: 1,
        maxW: 3,
        minH: 3,
        maxH: 3,
      },
      demoDrawerContent: (
        <AuthorizeWidgetComponent
          key="_VehicleRunningWidget"
          id="_VehicleRunningWidget"
          layout={this.state.layout}
          matching_name="vehicle_status_widget"
          permission={this.props.auth.profile.permissions}
        >
          <VehicleStatusWidget
            demo={true}
            widgetType={"running"}
            widgetText={
              <LangContext.Consumer>
                {(i18n) => i18n.r.running}
              </LangContext.Consumer>
            }
            color={"#28a745"}
            qty={24}
          />
        </AuthorizeWidgetComponent>
      ),
      widgetContent: (callingApi) => (
        <AuthorizeWidgetComponent
          key="VehicleRunningWidget"
          id="VehicleRunningWidget"
          layout={this.state.layout}
          matching_name="vehicle_status_widget"
          permission={this.props.auth.profile.permissions}
        >
          <VehicleStatusWidget
            auth={this.props.auth}
            demo={false}
            widgetType={"running"}
            widgetText={
              <LangContext.Consumer>
                {(i18n) => i18n.r.running}
              </LangContext.Consumer>
            }
            color={"#28a745"}
            qty={this.state.running}
            handdleParentStateChange={this.handdleParentStateChange}
            callingApi={callingApi}
          />
        </AuthorizeWidgetComponent>
      ),
    },
    {
      id: "VehicleStopWithEngineOffWidget",
      layout: {
        i: "VehicleStopWithEngineOffWidget",
        x: 13,
        y: 3,
        w: 3,
        h: 3,
        minW: 1,
        maxW: 3,
        minH: 3,
        maxH: 3,
      },
      demoDrawerContent: (
        <AuthorizeWidgetComponent
          key="_VehicleStopWithEngineOffWidget"
          id="_VehicleStopWithEngineOffWidget"
          layout={this.state.layout}
          matching_name="vehicle_status_widget"
          permission={this.props.auth.profile.permissions}
        >
          <VehicleStatusWidget
            demo={true}
            widgetType={"engineoff"}
            widgetText={
              <LangContext.Consumer>
                {(i18n) => i18n.e.engineOff}
              </LangContext.Consumer>
            }
            color={"#dc3545"}
            qty={12}
          />
        </AuthorizeWidgetComponent>
      ),
      widgetContent: (callingApi) => (
        <AuthorizeWidgetComponent
          key="VehicleStopWithEngineOffWidget"
          id="VehicleStopWithEngineOffWidget"
          layout={this.state.layout}
          matching_name="vehicle_status_widget"
          permission={this.props.auth.profile.permissions}
        >
          <VehicleStatusWidget
            auth={this.props.auth}
            demo={false}
            widgetType={"engineoff"}
            widgetText={
              <LangContext.Consumer>
                {(i18n) => i18n.e.engineOff}
              </LangContext.Consumer>
            }
            color={"#dc3545"}
            qty={this.state.stop_with_engine_off}
            handdleParentStateChange={this.handdleParentStateChange}
            callingApi={callingApi}
          />
        </AuthorizeWidgetComponent>
      ),
    },
    {
      id: "VehicleStopWithEngineOnWidget",
      layout: {
        i: "VehicleStopWithEngineOnWidget",
        x: 13,
        y: 6,
        w: 3,
        h: 3,
        minW: 1,
        maxW: 3,
        minH: 3,
        maxH: 3,
      },
      demoDrawerContent: (
        <AuthorizeWidgetComponent
          key="_VehicleStopWithEngineOnWidget"
          id="_VehicleStopWithEngineOnWidget"
          layout={this.state.layout}
          matching_name="vehicle_status_widget"
          permission={this.props.auth.profile.permissions}
        >
          <VehicleStatusWidget
            demo={true}
            widgetType={"stopwithengineon"}
            widgetText={
              <LangContext.Consumer>
                {(i18n) => i18n.s.stopWithEngineOn}
              </LangContext.Consumer>
            }
            color={"#ffc107"}
            qty={6}
          />
        </AuthorizeWidgetComponent>
      ),
      widgetContent: (callingApi) => (
        <AuthorizeWidgetComponent
          key="VehicleStopWithEngineOnWidget"
          id="VehicleStopWithEngineOnWidget"
          layout={this.state.layout}
          matching_name="vehicle_status_widget"
          permission={this.props.auth.profile.permissions}
        >
          <VehicleStatusWidget
            auth={this.props.auth}
            demo={false}
            widgetType={"stopwithengineon"}
            widgetText={
              <LangContext.Consumer>
                {(i18n) => i18n.s.stopWithEngineOn}
              </LangContext.Consumer>
            }
            color={"#ffc107"}
            qty={this.state.stop_with_engine_on}
            handdleParentStateChange={this.handdleParentStateChange}
            callingApi={callingApi}
          />
        </AuthorizeWidgetComponent>
      ),
    },
    {
      id: "VehicleConnectionErrorWidget",
      layout: {
        i: "VehicleConnectionErrorWidget",
        x: 13,
        y: 6,
        w: 3,
        h: 3,
        minW: 1,
        maxW: 3,
        minH: 3,
        maxH: 3,
      },
      demoDrawerContent: (
        <AuthorizeWidgetComponent
          key="_VehicleConnectionErrorWidget"
          id="_VehicleConnectionErrorWidget"
          layout={this.state.layout}
          matching_name="vehicle_status_widget"
          permission={this.props.auth.profile.permissions}
        >
          <VehicleStatusWidget
            demo={true}
            widgetType={"connection_error"}
            widgetText={
              <LangContext.Consumer>
                {(i18n) => i18n.d.disconnected}
              </LangContext.Consumer>
            }
            color={"#848484"}
            qty={2}
          />
        </AuthorizeWidgetComponent>
      ),
      widgetContent: (callingApi) => (
        <AuthorizeWidgetComponent
          key="VehicleConnectionErrorWidget"
          id="VehicleConnectionErrorWidget"
          layout={this.state.layout}
          matching_name="vehicle_status_widget"
          permission={this.props.auth.profile.permissions}
        >
          <VehicleStatusWidget
            auth={this.props.auth}
            demo={false}
            widgetType={"connection_error"}
            widgetText={
              <LangContext.Consumer>
                {(i18n) => i18n.d.disconnected}
              </LangContext.Consumer>
            }
            color={"#848484"}
            qty={this.state.connection_error}
            handdleParentStateChange={this.handdleParentStateChange}
            callingApi={callingApi}
          />
        </AuthorizeWidgetComponent>
      ),
    },
    {
      id: "SpeedOverlimitWidget",
      layout: {
        i: "SpeedOverlimitWidget",
        x: 0,
        y: 0,
        w: 8,
        h: 8,
        minW: 4,
        maxW: 12,
        minH: 5,
        maxH: 10,
        config: { test: 20 },
      },
      demoDrawerContent: (
        <AuthorizeWidgetComponent
          style={{ height: "220px" }}
          key="_SpeedOverlimitWidget"
          id="_SpeedOverlimitWidget"
          layout={this.state.layout}
          matching_name="speed_over_limit_bar_chart_widget"
          permission={this.props.auth.profile.permissions}
        >
          <SpeedOverlimitWidget demo={true} />
        </AuthorizeWidgetComponent>
      ),
      widgetContent: () => (
        <AuthorizeWidgetComponent
          key="SpeedOverlimitWidget"
          id="SpeedOverlimitWidget"
          layout={this.state.layout}
          matching_name="speed_over_limit_bar_chart_widget"
          permission={this.props.auth.profile.permissions}
        >
          <SpeedOverlimitWidget auth={this.props.auth} demo={false} />
        </AuthorizeWidgetComponent>
      ),
    },
    {
      id: "VehicleStatusPieChartWidget",
      layout: {
        i: "VehicleStatusPieChartWidget",
        x: 0,
        y: 8,
        w: 6,
        h: 8,
        minW: 4,
        maxW: 9,
        minH: 5,
        maxH: 10,
      },
      demoDrawerContent: (
        <AuthorizeWidgetComponent
          style={{ height: "220px" }}
          key="_VehicleStatusPieChartWidget"
          id="_VehicleStatusPieChartWidget"
          layout={this.state.layout}
          matching_name="vehicle_status_widget"
          permission={this.props.auth.profile.permissions}
        >
          <VehicleStatusPieChartWidget demo={true} />
        </AuthorizeWidgetComponent>
      ),
      widgetContent: (callingApi) => (
        <AuthorizeWidgetComponent
          key="VehicleStatusPieChartWidget"
          id="VehicleStatusPieChartWidget"
          layout={this.state.layout}
          matching_name="vehicle_status_widget"
          permission={this.props.auth.profile.permissions}
        >
          <VehicleStatusPieChartWidget
            auth={this.props.auth}
            demo={false}
            handdleParentStateChange={this.handdleParentStateChange}
            vehicleStatusPieData={this.state.vehicleStatusPieData}
            callingApi={callingApi}
          />
        </AuthorizeWidgetComponent>
      ),
    },
    {
      id: "DriverSpeedOverLimitWidget",
      layout: {
        i: "DriverSpeedOverLimitWidget",
        x: 0,
        y: 13,
        w: 8,
        h: 7,
        minW: 6,
        maxW: 12,
        minH: 7,
        maxH: 7,
      },
      demoDrawerContent: (
        <AuthorizeWidgetComponent
          style={{ height: "220px" }}
          key="_DriverSpeedOverLimitWidget"
          id="_DriverSpeedOverLimitWidget"
          layout={this.state.layout}
          matching_name="driver_speed_overlimit_widget"
          permission={this.props.auth.profile.permissions}
        >
          <DriverSpeedOverLimitWidget demo={true} />
        </AuthorizeWidgetComponent>
      ),
      widgetContent: () => (
        <AuthorizeWidgetComponent
          key="DriverSpeedOverLimitWidget"
          id="DriverSpeedOverLimitWidget"
          layout={this.state.layout}
          matching_name="driver_speed_overlimit_widget"
          permission={this.props.auth.profile.permissions}
        >
          <DriverSpeedOverLimitWidget auth={this.props.auth} demo={false} />
        </AuthorizeWidgetComponent>
      ),
    },
    {
      id: "SpeedOverLimitByVehicleGroupWidget",
      layout: {
        i: "SpeedOverLimitByVehicleGroupWidget",
        x: 0,
        y: 13,
        w: 8,
        h: 7,
        minW: 6,
        maxW: 12,
        minH: 7,
        maxH: 15,
      },
      demoDrawerContent: (
        <AuthorizeWidgetComponent
          style={{ height: "220px" }}
          key="_SpeedOverLimitByVehicleGroupWidget"
          id="_SpeedOverLimitByVehicleGroupWidget"
          layout={this.state.layout}
          matching_name="speed_over_limit_by_vehicle_group_chart_widget"
          permission={this.props.auth.profile.permissions}
        >
          <SpeedOverLimitByVehicleGroupWidget demo={true} />
        </AuthorizeWidgetComponent>
      ),
      widgetContent: () => (
        <AuthorizeWidgetComponent
          key="SpeedOverLimitByVehicleGroupWidget"
          id="SpeedOverLimitByVehicleGroupWidget"
          layout={this.state.layout}
          matching_name="speed_over_limit_by_vehicle_group_chart_widget"
          permission={this.props.auth.profile.permissions}
        >
          <SpeedOverLimitByVehicleGroupWidget
            auth={this.props.auth}
            demo={false}
          />
        </AuthorizeWidgetComponent>
      ),
    },
    {
      id: "DrivingLicenseExpireWidget",
      layout: {
        i: "DrivingLicenseExpireWidget",
        x: 0,
        y: 13,
        w: 8,
        h: 7,
        minW: 6,
        maxW: 12,
        minH: 7,
        maxH: 15,
      },
      demoDrawerContent: (
        <AuthorizeWidgetComponent
          style={{ height: "220px" }}
          key="_DrivingLicenseExpireWidget"
          id="_DrivingLicenseExpireWidget"
          layout={this.state.layout}
          matching_name="driving_license_expire_widget"
          permission={this.props.auth.profile.permissions}
        >
          <DrivingLicenseExpireWidget demo={true} />
        </AuthorizeWidgetComponent>
      ),
      widgetContent: () => (
        <AuthorizeWidgetComponent
          key="DrivingLicenseExpireWidget"
          id="DrivingLicenseExpireWidget"
          layout={this.state.layout}
          matching_name="driving_license_expire_widget"
          permission={this.props.auth.profile.permissions}
        >
          <DrivingLicenseExpireWidget auth={this.props.auth} demo={false} />
        </AuthorizeWidgetComponent>
      ),
    },
  ];

  handdleParentStateChange = (key, value) => {
    this.setState({ [key]: value });
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  handleCheckedBoxWidgetCheck = (check, selectedEle) => {
    if (check) {
      let layout = [...this.state.layout, selectedEle.layout];

      this.setState({
        layout: layout,
      });
    } else {
      let layout = this.state.layout.filter((ele) => ele.i != selectedEle.id);
      this.setState({
        layout: layout,
      });
    }
  };

  updateUserConfig = (layout) => {
    let self = this;
    fetch(
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/updateuserconfighomedashboard`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${self.props.auth.accessToken}`,
        },
        body: JSON.stringify({
          user_id: self.props.profile.id,
          layout: JSON.stringify(layout),
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        // self.props.changeConfigTrackingColumns(data);
      })
      .catch(function() {});
  };

  componentDidMount() {
    if (
      typeof this.props.auth.profile.config.home_dashboard.layout != "undefined"
    )
      this.setState({
        layout: this.props.auth.profile.config.home_dashboard.layout,
      });
  }

  componentWillUnmount() {
    this.updateUserConfig(this.state.layout);
    this.props.changeLocalStorageConfigHomeDashboardLayout(
      this.props.auth.profile,
      this.state.layout
    );
  }

  render() {
    let vehicleStatusCoDataAssinged = false;
    let widget = this.listAllofWidget.filter((ele) =>
      this.state.layout.find((item) => item.i == ele.id)
    );

    return (
      // <Tabs
      //   defaultActiveKey="1"
      //   onChange={(val) =>
      //     this.setState({
      //       tabId: val,
      //     })
      //   }
      //   className={this.state.tabId == 1 ? "TabsStylingHomeDashboard" : ""}
      // >
      //   <TabPane tab="News & Manuals" key="1">
      //     <News />
      //   </TabPane>
      //   <TabPane tab="Widget" key="2">
      <div>
        <Button
          style={{ margin: "0px 0px 0px 10px" }}
          type="primary"
          size="small"
          onClick={this.showDrawer}
        >
          <Icon type="control" />{" "}
          <LangContext.Consumer>{(i18n) => i18n.c.custom}</LangContext.Consumer>
        </Button>

        <Drawer
          title="Widget"
          width={window.innerWidth - 200}
          onClose={this.onClose}
          visible={this.state.visible}
          // bodyStyle={{ paddingBottom: 80 }}
        >
          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 2,
              md: 2,
              lg: 3,
              xl: 3,
              xxl: 3,
            }}
            dataSource={this.listAllofWidget}
            renderItem={(item) => {
              const {
                permission,
                matching_name,
              } = item.demoDrawerContent.props;
              let canAccess = permission.find((ele) => {
                return ele.matching_name == matching_name && ele.can_view == 1;
              });

              if (matching_name == "") {
                canAccess = true;
              } else if (typeof canAccess == "undefined") {
                canAccess = false;
              } else {
                canAccess = true;
              }

              if (!canAccess) {
                return <div></div>;
              }

              return (
                <List.Item>
                  <Card
                    title={
                      <Switch
                        checkedChildren={<Icon type="check" />}
                        unCheckedChildren={<Icon type="close" />}
                        defaultChecked={
                          typeof this.state.layout.find(
                            (ele) => ele.i == item.id
                          ) != "undefined"
                        }
                        onChange={(check) =>
                          this.handleCheckedBoxWidgetCheck(check, item)
                        }
                      />
                    }
                  >
                    <Row>{item.demoDrawerContent}</Row>
                  </Card>
                </List.Item>
              );
            }}
          />
        </Drawer>

        <GridLayout
          className="layout"
          // layout={layout}
          layout={this.state.layout}
          // onLayoutChange={e => {
          //   console.log(this.state.layout);
          //   this.setState({ layout: e });
          //   console.log(e);
          // }}
          onDragStop={(e) => {
            this.setState({ layout: e });
          }}
          onResizeStop={(e) => {
            this.setState({ layout: e });
          }}
          cols={12}
          rowHeight={30}
          width={window.innerWidth - 140}
        >
          {widget.map((ele) => {
            if (
              this.state.vehicleStatusCoDataUsingComponentList.find(
                (c) => ele.id == c
              )
            ) {
              if (!vehicleStatusCoDataAssinged) {
                vehicleStatusCoDataAssinged = true;
                return ele.widgetContent(true);
              }
              return ele.widgetContent(false);
            } else {
              return ele.widgetContent();
            }
          })}
        </GridLayout>
      </div>
      //   </TabPane>
      // </Tabs>
    );
  }
}

export default connect(
  ({ auth, auth: { profile } }) => ({
    auth,
    profile,
  }),
  {
    changeConfigHomeDashboardLayout,
    changeLocalStorageConfigHomeDashboardLayout,
  }
)(Index);
