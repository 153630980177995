import React from "react";
import { Switch, Route } from "react-router-dom";
import Index from "./components/Index";
import NewsCreate from "./components/NewsCreate";
import NewsEdit from "./components/NewsEdit";
import NewsDetail from "./components/NewsDetail";
import ManualCreate from "./components/ManualCreate";
import ManualDetail from "./components/ManualDetail";
import ManualEdit from "./components/ManualEdit";

export default () => (
  <Switch>
    <Route exact path="/news-manuals" component={Index} />
    <Route path="/news-manuals/news_create" component={NewsCreate} />
    <Route path="/news-manuals/news_detail/:id" component={NewsDetail} />
    <Route path="/news-manuals/news_edit/:id" component={NewsEdit} />
    <Route path="/news-manuals/manuals_create" component={ManualCreate} />
    <Route path="/news-manuals/manuals_detail/:id" component={ManualDetail} />
    <Route path="/news-manuals/manuals_edit/:id" component={ManualEdit} />
  </Switch>
);
