// Index.js
import React, { Component, Fragment } from "react";
import { Row, Col, Divider, Spin, message, Switch, Typography } from "antd";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";
import { connect } from "react-redux";
import SettingComponent from "./Setting";
import Licensesetting from "./Licensesetting";
import Linesetting from "./Linesetting";
import Breadcrumb from "./../../../shared/components/Breadcrumb";
import { loadCompanySetting } from "../actions";
import Axios from "axios";
import styled from "styled-components";
import UserGroupTransfer from "./UserGroupTransfer";
import { getUserEventSetting } from "../fetchAPI/apiClient";

const Description = styled.div`
  font-size: 12px;
  font-weight: normal;
  margin-left: 52px;
`;

const { Text } = Typography;

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userSelected: [],
      selectedSetting: "1",
      settingId: "",
      vehicles: [],
      data: [
        {
          settingId: "",
          id: "",
          line_token: "",
          vehicle_id: [],
        },
      ],
      installAdas: false,
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true }, () => {
      this.loadVehicles();
      this.loadData();
    });
  }

  loadData = (loading) => {
    this.props.loadCompanySetting(
      {
        companyID: this.props.auth.profile.company_id,
        accessToken: this.props.auth.accessToken,
      },
      loading
    );
  };

  loadVehicles = async () => {
    try {
      const url = `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/getvehiclebygroupid`;
      const params = {
        companyID: this.props.auth.profile.company_id,
        vehicletypeID: "",
        allow_vehicles: this.props.auth.profile.vehicle_visibility,
      };
      const headers = {
        headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
      };
      const res = await Axios.post(url, params, headers);

      const results = res.data.data.map((x) => {
        return {
          key: x.id,
          title: x.plate_no,
          disabled: false,
          ...x,
        };
      });
      this.setState({
        vehicles: results,
        loading: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleUpdateToken = async (data, removeId) => {
    this.setState({ loading: true });
    try {
      const url = `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/company-setting/updateLineConfig`;
      const params = {
        company_id: this.props.auth.profile.company_id,
        setting_id: this.state.settingId,
        data: data,
        remove_list: removeId.filter((x) => x !== ""),
      };
      const headers = {
        headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
      };
      await Axios.post(url, params, headers);
      message.success("บันทึกสำเร็จ");
      this.handleLoadLineSetting();
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  };

  handleLoadLineSetting = async () => {
    this.setState({ loading: true });
    try {
      const url = `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/company-setting/getLineConfig`;
      const params = {
        company_id: this.props.auth.profile.company_id,
        setting_id: this.state.settingId,
      };
      const headers = {
        headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
      };
      const res = await Axios.post(url, params, headers);

      this.setState({
        loading: false,
        data: res.data.data.data,
        installAdas: res.data.data.setting_install_adas,
      });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  };

  getSettingId = (setting) => {
    switch (setting) {
      case "2":
        return "1";
      case "3":
        return "2";
      case "4":
        return "3";
      case "5":
        return "4";
      default:
        return "";
    }
  };

  handleSettingClick = (setting) => {
    let settingId = this.getSettingId(setting);
    this.setState(
      {
        selectedSetting: setting,
        settingId: settingId,
        data: [
          {
            settingId: "",
            id: "",
            line_token: "",
            vehicle_id: [],
          },
        ],
      },
      () => {
        if (settingId !== "") {
          this.handleLoadLineSetting();
          getUserEventSetting(
            {
              company_id: this.props.auth.profile.company_id,
              setting_id: settingId,
            },
            (status, res) => {
              this.setState({
                userSelected: res.data.data.user,
              });
            }
          );
        } else {
          this.loadData();
        }
      }
    );
  };

  handleUpdateInstallAds = async (checked) => {
    let value = checked ? 1 : 0;
    this.setState({
      loading: true,
      installAdas: value,
    });

    try {
      const url = `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/company-setting/updateAdasInstall`;
      const params = {
        company_id: this.props.auth.profile.company_id,
        is_setting: value,
      };
      const headers = {
        headers: { Authorization: `Bearer ${this.props.auth.accessToken}` },
      };
      await Axios.post(url, params, headers);

      this.setState({
        loading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  };

  renderInformationComponent() {
    const { selectedSetting } = this.state;

    if (selectedSetting === "1") {
      return (
        <Licensesetting
          licensesetting={this.props.companysetting}
          auth={this.props.auth}
        />
      );
    } else {
      return (
        <UserGroupTransfer
          selectedSetting={this.getSettingId(selectedSetting)}
          auth={this.props.auth}
          userSelected={this.state.userSelected}
          type={"edit"}
        />
      );
    }
  }

  render() {
    const { loading } = this.props.companysetting;
    return (
      // <AuthorizeComponent matching_name="companysetting">
      <GeneralStyledContent>
        <Row gutter={24}>
          <Col span={24}>
            <Breadcrumb
              match={this.props.match}
              style={{ margin: "0px 0px 14px 10px" }}
            />
          </Col>
        </Row>
        <Row>
          <Spin spinning={loading || this.state.loading}>
            <Col span={6}>
              <SettingComponent
                onSettingClick={this.handleSettingClick}
                defaultSelectedSetting={this.state.selectedSetting}
              />
            </Col>

            <Col span={1}>
              <Divider type="vertical" style={{ height: "80vh" }} />
            </Col>

            <Col span={17}>{this.renderInformationComponent()}</Col>
          </Spin>
        </Row>
      </GeneralStyledContent>
      // </AuthorizeComponent>
    );
  }
}

export default connect(
  ({ companysetting, auth }) => ({ companysetting, auth }),
  {
    loadCompanySetting: loadCompanySetting.request,
  }
)(Index);
