import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  Col,
  Row,
  Spin,
  Table,
  Button,
  Switch,
  Tooltip,
  Icon,
  Popconfirm,
  Select,
  Input,
  message,
} from "antd";
import LangContext from "modules/shared/context/langContext";
import { TableStyled } from "./StyledComponent";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ReactComponent as Holder } from "../Asset/Holder.svg";
import { Link, Redirect } from "react-router-dom";
import {
  getNews,
  getNewsCategory,
  deleteNews,
  updateStatus,
  updatePriorlitize,
} from "../fetchApi/apiClient";
import { Icons } from "./Constant";

const RowTable = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };
  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners,
    }),
    [setActivatorNodeRef, listeners]
  );
  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

const RowContext = React.createContext({});
const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="link"
      ghost
      ref={setActivatorNodeRef}
      {...listeners}
      size="small"
    >
      <Holder />
    </Button>
  );
};

const NewsMain = () => {
  const [loading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [filters, setFilters] = useState({
    search: "",
    category: "all",
    status: "all",
  });
  const [newsCategory, setNewsCategory] = useState([]);

  useEffect(() => {
    setLoading(true);
    getNews({}, (status, res) => {
      const updatedResponseData = res.data.data.map((item) => ({
        ...item,
        active: true,
      }));
      setDataTable(updatedResponseData);
      setLoading(false);
    });
    getNewsCategory({}, (status, res) => {
      setNewsCategory(res.data.data);
    });
  }, []);

  const onDragEnd = ({ active, over }) => {
    if (over.id != null) {
      if (active.id !== over.id) {
        setDataTable((prev) => {
          const activeIndex = prev.findIndex((i) => i.id === active.id);
          const overIndex = prev.findIndex((i) => i.id === over.id);
          console.log(
            "onDragEnd",
            arrayMove(prev, activeIndex, overIndex).map((item) => item.id)
          );

          updatePriorlitize(
            {
              priorities: arrayMove(prev, activeIndex, overIndex).map(
                (item) => item.id
              ),
            },
            (status, res) => {
              message.success("อัพเดตตารางสำเร็จ");
            }
          );

          return arrayMove(prev, activeIndex, overIndex);
        });
      }
    }
  };

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const filterData = (data, filters) => {
    return data.map((item) => {
      // Search filter
      const matchesSearch = item.topic
        .toLowerCase()
        .includes(filters.search.toLowerCase());

      // Category filter
      const matchesCategory =
        filters.category === "all" ||
        item.news_category_id === Number(filters.category);

      // Status filter
      const matchesStatus =
        filters.status === "all" ||
        item.is_publish === (filters.status === "true");

      // Update `active` field based on filter conditions
      const isActive = matchesSearch && matchesCategory && matchesStatus;
      return { ...item, active: isActive };
    });
  };

  const applyFilters = (dataTable) => {
    const filteredData = filterData(dataTable, filters);
    setDataTable(filteredData);
  };

  const columns = [
    {
      title: "",
      dataIndex: "",
      key: "",
      align: "center",
      width: 5,
      render: () => <DragHandle />,
    },
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      align: "center",
      width: 50,
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: <LangContext>{(i18n) => i18n.t.titleName}</LangContext>,
      dataIndex: "topic",
      key: "topic",
      align: "center",
      width: 100,
    },
    {
      title: <LangContext>{(i18n) => i18n.c.catagory}</LangContext>,
      dataIndex: "news_category_id",
      key: "news_category_id",
      align: "center",
      render: (text, record, index) => {
        return (
          <LangContext>
            {(i18n) => i18n.newsManuals[Icons[text].label]}
          </LangContext>
        );
      },
    },
    {
      title: <LangContext>{(i18n) => i18n.c.creationDate}</LangContext>,
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
    },
    {
      title: (
        <>
          <LangContext>{(i18n) => i18n.s.status}</LangContext>
          {" ("}
          <LangContext>{(i18n) => i18n.o.open}</LangContext>/
          <LangContext>{(i18n) => i18n.c.close}</LangContext>
          {")"}
        </>
      ),
      dataIndex: "is_publish",
      key: "is_publish",
      align: "center",
      render: (text, record, index) => {
        return (
          <Switch
            checkedChildren={"Publish"}
            unCheckedChildren={"Publish"}
            checked={text}
            onClick={(checked) => {
              setLoading(true);
              updateStatus(
                record.id,
                {
                  is_publish: checked,
                },
                () => {
                  getNews({}, (status, res) => {
                    const updatedResponseData = res.data.data.map((item) => ({
                      ...item,
                      active: true,
                    }));
                    applyFilters(updatedResponseData);
                    setLoading(false);
                  });
                }
              );
            }}
          />
        );
      },
    },
    {
      title: <LangContext>{(i18n) => i18n.a.action}</LangContext>,
      dataIndex: "",
      key: "",
      align: "center",
      width: 80,
      render: (text, record, index) => {
        return (
          <Row>
            <Col span={8}>
              <Tooltip
                placement="left"
                title={
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.detail}
                  </LangContext.Consumer>
                }
                arrowPointAtCenter
              >
                <Link to={`/news-manuals/news_detail/${record.id}`}>
                  <Icon type="file-text" />
                </Link>
              </Tooltip>
            </Col>
            <Col span={8}>
              <Tooltip
                placement="left"
                title={
                  <LangContext.Consumer>
                    {(i18n) => i18n.e.edit}
                  </LangContext.Consumer>
                }
                arrowPointAtCenter
              >
                <Link to={`/news-manuals/news_edit/${record.id}`}>
                  <Icon type="edit" />
                </Link>
              </Tooltip>
            </Col>
            <Col span={8}>
              <Tooltip
                placement="left"
                title={
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.delete}
                  </LangContext.Consumer>
                }
                arrowPointAtCenter
              >
                <Popconfirm
                  title={
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.doYouWantToDeleteTheseItems}
                    </LangContext.Consumer>
                  }
                  okText={
                    <LangContext.Consumer>
                      {(i18n) => i18n.o.okay}
                    </LangContext.Consumer>
                  }
                  cancelText={
                    <LangContext.Consumer>
                      {(i18n) => i18n.c.cancel}
                    </LangContext.Consumer>
                  }
                  onConfirm={() => {
                    setLoading(true);
                    deleteNews(record.id, (status, res) => {
                      getNews({}, (status, res) => {
                        const updatedResponseData = res.data.data.map(
                          (item) => ({
                            ...item,
                            active: true,
                          })
                        );
                        setDataTable(updatedResponseData);
                        setLoading(false);
                      });
                      setLoading(false);
                      window.alert("ลบสำเร็จ");
                    });
                  }}
                  placement="left"
                >
                  <a href="#">
                    <Icon type="delete" />
                  </a>
                </Popconfirm>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <Spin spinning={loading}>
      <LangContext.Consumer>
        {(i18n) => (
          <Row gutter={[8, 8]}>
            <Col span={8}>
              <Link to="/news-manuals/news_create">
                <Button
                  style={{
                    width: 100,
                    backgroundColor: "#1890ff",
                    color: "white",
                    borderRadius: 16,
                  }}
                >
                  {i18n.a.add}
                </Button>
              </Link>
            </Col>
            <Col span={16} align="right">
              <Input
                placeholder={i18n.t.titleName}
                style={{ width: 300, marginRight: 10 }}
                value={filters.search}
                onChange={(e) => handleFilterChange("search", e.target.value)}
              />
              <Select
                placeholder={i18n.c.catagory}
                style={{ width: 300, marginRight: 10 }}
                value={filters.category}
                onChange={(value) => handleFilterChange("category", value)}
              >
                <Select.Option value={"all"}>
                  {i18n.c.catagory}
                  {i18n.a.all}
                </Select.Option>
                {newsCategory.map((item) => (
                  <Select.Option value={item.id}>
                    {i18n.newsManuals[item.name]}
                  </Select.Option>
                ))}
              </Select>
              <Select
                placeholder={i18n.s.status}
                style={{ width: 300, marginRight: 10 }}
                value={filters.status}
                onChange={(value) => handleFilterChange("status", value)}
              >
                <Select.Option value={"all"}>
                  {i18n.s.status}
                  {i18n.a.all}
                </Select.Option>
                <Select.Option value="true">{i18n.o.open}</Select.Option>
                <Select.Option value="false">{i18n.c.close}</Select.Option>
              </Select>
              <Button type="primary" onClick={() => applyFilters(dataTable)}>
                {i18n.s.search}
              </Button>
            </Col>
            <Col span={24}>
              <DndContext
                modifiers={[restrictToVerticalAxis]}
                onDragEnd={onDragEnd}
              >
                <SortableContext
                  items={dataTable.map((i) => i.id)}
                  strategy={verticalListSortingStrategy}
                >
                  <TableStyled
                    rowClassName={(record, index) => {
                      if (!record.active) {
                        return "nonedisplaytest";
                      }
                    }}
                    columns={columns}
                    bordered
                    dataSource={
                      dataTable.find((item) => item.active == true) == undefined
                        ? []
                        : dataTable
                    }
                    rowKey="id"
                    components={{
                      body: {
                        row: RowTable,
                      },
                    }}
                    pagination={false}
                  />
                </SortableContext>
              </DndContext>
            </Col>
          </Row>
        )}
      </LangContext.Consumer>
    </Spin>
  );
};

export default NewsMain;
