import React from "react";
import FormNews from "./FormNews";
import { createNews } from "../fetchApi/apiClient";

const NewsCreate = () => {
  return (
    <FormNews
      initialValues={{
        titleName: "",
        newsCatagory: "1",
        content: "",
        isPublish: false,
      }}
      readOnly={false}
      onSubmit={(val) => {
        createNews(
          {
            topic: val.titleName,
            news_category_id: val.newsCatagory,
            detail: val.content,
            is_publish: val.isPublish,
          },
          (status, res) => {
            window.alert("สร้างสำเร็จ");
            window.location.replace("/news-manuals");
          }
        );
      }}
    />
  );
};

export default NewsCreate;
