import React from "react";
import FormManuals from "./FormManuals";
import { createManuals } from "../fetchApi/apiClient";

const ManualCreate = () => {
  return (
    <FormManuals
      initialValues={{
        titleName: undefined,
        page: undefined,
        link: undefined,
        isPublish: false,
      }}
      readOnly={false}
      onSubmit={(val) => {
        createManuals(
          {
            topic: val.titleName,
            manual_link: val.link,
            is_publish: val.isPublish,
            m_permission_id: val.page,
          },
          (status, res) => {
            window.alert("สร้างสำเร็จ");
            window.location.replace("/news-manuals");
          }
        );
      }}
    />
  );
};

export default ManualCreate;
