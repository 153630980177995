import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Spin,
  Button,
  Switch,
  Tooltip,
  Icon,
  Popconfirm,
  Select,
  Pagination,
} from "antd";
import LangContext from "modules/shared/context/langContext";
import { TableStyled } from "./StyledComponent";
import { menuTable } from "./mockup";
import { Link, Redirect } from "react-router-dom";
import {
  getManual,
  menuPermission,
  deleteManuals,
  updateManualsStatus,
} from "../fetchApi/apiClient";

const ManualsMain = () => {
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    orderBy: "id",
    orderType: "asc",
  });
  const [total, setTotal] = useState(0);
  const [dataTable, setDataTable] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [menuSelected, setMenuSelected] = useState("all");

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      align: "center",
      width: 50,
    },
    {
      title: <LangContext>{(i18n) => i18n.m.menuName}</LangContext>,
      dataIndex: "topic",
      key: "topic",
      align: "center",
      width: 200,
    },
    {
      title: <LangContext>{(i18n) => i18n.u.updateToPage}</LangContext>,
      dataIndex: "m_permission_id",
      key: "m_permission_id",
      align: "center",
      width: 300,
      render: (text, record, index) => {
        return (
          <>
            {menuList.find((item) => item.id == text) != undefined
              ? menuList.find((item) => item.id == text)["name"]
              : "-"}
          </>
        );
      },
    },
    {
      title: <LangContext>{(i18n) => i18n.l.link}</LangContext>,
      dataIndex: "manual_link",
      key: "manual_link",
      align: "left",
      width: 300,
      render: (text, record, index) => {
        return (
          <label
          // style={{
          //   width: 400,
          //   display: "block",
          //   whiteSpace: "nowrap",
          //   overflow: "hidden",
          //   textOverflow: "ellipsis",
          // }}
          >
            {text}
          </label>
        );
      },
    },
    {
      title: (
        <>
          <LangContext>{(i18n) => i18n.s.status}</LangContext>
          {" ("}
          <LangContext>{(i18n) => i18n.o.open}</LangContext>/
          <LangContext>{(i18n) => i18n.c.close}</LangContext>
          {")"}
        </>
      ),
      dataIndex: "is_publish",
      key: "is_publish",
      align: "center",
      width: 100,
      render: (text, record, index) => {
        return (
          <Switch
            checkedChildren={"Publish"}
            unCheckedChildren={"Publish"}
            checked={text}
            onClick={(checked) => {
              setLoading(true);
              updateManualsStatus(
                record.id,
                {
                  is_publish: checked,
                },
                () => {
                  getManual(
                    {
                      m_permission_id:
                        menuSelected === "all" ? undefined : menuSelected,
                      page: pagination.page,
                      pageSize: pagination.pageSize,
                      orderBy: "id",
                      orderType: "asc",
                    },
                    (status, res) => {
                      setDataTable(res.data.data.data);
                      setTotal(res.data.data.total);
                      setLoading(false);
                    }
                  );
                }
              );
            }}
          />
        );
      },
    },
    {
      title: <LangContext>{(i18n) => i18n.a.action}</LangContext>,
      dataIndex: "",
      key: "",
      align: "center",
      width: 80,
      render: (text, record, index) => {
        return (
          <Row>
            <Col span={8}>
              <Tooltip
                placement="left"
                title={
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.detail}
                  </LangContext.Consumer>
                }
                arrowPointAtCenter
              >
                <Link to={`/news-manuals/manuals_detail/${record.id}`}>
                  <Icon type="file-text" />
                </Link>
              </Tooltip>
            </Col>
            <Col span={8}>
              <Tooltip
                placement="left"
                title={
                  <LangContext.Consumer>
                    {(i18n) => i18n.e.edit}
                  </LangContext.Consumer>
                }
                arrowPointAtCenter
              >
                <Link to={`/news-manuals/manuals_edit/${record.id}`}>
                  <Icon type="edit" />
                </Link>
              </Tooltip>
            </Col>
            <Col span={8}>
              <Tooltip
                placement="left"
                title={
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.delete}
                  </LangContext.Consumer>
                }
                arrowPointAtCenter
              >
                <Popconfirm
                  title={
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.doYouWantToDeleteTheseItems}
                    </LangContext.Consumer>
                  }
                  okText={
                    <LangContext.Consumer>
                      {(i18n) => i18n.o.okay}
                    </LangContext.Consumer>
                  }
                  cancelText={
                    <LangContext.Consumer>
                      {(i18n) => i18n.c.cancel}
                    </LangContext.Consumer>
                  }
                  onConfirm={() => {
                    deleteManuals(record.id, (status, res) => {
                      window.alert("ลบสำเร็จ");
                      getManual(
                        {
                          m_permission_id:
                            menuSelected === "all" ? undefined : menuSelected,
                          page: 1,
                          pageSize: 10,
                          orderBy: "id",
                          orderType: "asc",
                        },
                        (status, res) => {
                          setDataTable(res.data.data.data);
                          setTotal(res.data.data.total);
                        }
                      );
                    });
                  }}
                  placement="left"
                >
                  <a href="#">
                    <Icon type="delete" />
                  </a>
                </Popconfirm>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];

  const onShowSizeChange = (current, pageSize) => {
    getManual(
      {
        m_permission_id: menuSelected === "all" ? undefined : menuSelected,
        page: current,
        pageSize: pageSize,
        orderBy: "id",
        orderType: "asc",
      },
      (status, res) => {
        setPagination((prev) => ({
          ...prev,
          page: current,
          pageSize,
        }));
        setDataTable(res.data.data.data);
        setTotal(res.data.data.total);
      }
    );
  };

  const onPaginationChange = (page, pageSize) => {
    getManual(
      {
        m_permission_id: menuSelected === "all" ? undefined : menuSelected,
        page,
        pageSize,
        orderBy: "id",
        orderType: "asc",
      },
      (status, res) => {
        setPagination((prev) => ({
          ...prev,
          page,
          pageSize,
        }));
        setDataTable(res.data.data.data);
        setTotal(res.data.data.total);
      }
    );
  };

  useEffect(() => {
    getManual(
      {
        m_permission_id: menuSelected === "all" ? undefined : menuSelected,
        page: 1,
        pageSize: 10,
        orderBy: "id",
        orderType: "asc",
      },
      (status, res) => {
        setDataTable(res.data.data.data);
        setTotal(res.data.data.total);
      }
    );
    menuPermission({}, (status, res) => {
      setMenuList(res.data.data.data);
    });
  }, []);

  const handleSubmit = () => {
    getManual(
      {
        m_permission_id: menuSelected === "all" ? undefined : menuSelected,
        page: 1,
        pageSize: pagination.pageSize,
        orderBy: "id",
        orderType: "asc",
      },
      (status, res) => {
        setDataTable(res.data.data.data);
        setTotal(res.data.data.total);
      }
    );
  };

  return (
    <Spin spinning={loading}>
      <LangContext.Consumer>
        {(i18n) => (
          <Row gutter={[8, 8]}>
            <Col span={8}>
              <Link to="/news-manuals/manuals_create">
                <Button
                  style={{
                    width: 100,
                    backgroundColor: "#1890ff",
                    color: "white",
                    borderRadius: 16,
                  }}
                >
                  {i18n.a.add}
                </Button>
              </Link>
            </Col>
            <Col span={16} align="right">
              <Select
                placeholder={i18n.m.menuName}
                value={menuSelected}
                style={{ width: 300, marginRight: 10 }}
                onChange={(value) => {
                  setMenuSelected(value);
                }}
                showSearch
                filterOption={(input, option) => {
                  if (typeof option.props.children != "object") {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }
                }}
              >
                <Select.Option value="all">เมนูทั้งหมด</Select.Option>
                {menuList.map((item) => (
                  <Select.Option value={item.id}>{item.name}</Select.Option>
                ))}
              </Select>

              <Button type="primary" onClick={handleSubmit}>
                ค้นหา
              </Button>
            </Col>
            <Col span={24}>
              <TableStyled
                columns={columns}
                bordered
                dataSource={dataTable}
                pagination={false}
              />
            </Col>
            <Col span={24} align="right">
              <Pagination
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                onChange={onPaginationChange}
                current={pagination.page}
                total={total}
              />
            </Col>
          </Row>
        )}
      </LangContext.Consumer>
    </Spin>
  );
};

export default ManualsMain;
