import React, { useCallback, useMemo, useState, useEffect } from "react";
import escapeHtml from "escape-html";
import { jsx } from "slate-hyperscript";
import { Editable, withReact, Slate } from "slate-react";
import { createEditor, Text } from "slate";
import { withHistory } from "slate-history";

const RichText = (props) => {
  const html = props.description ? props.description : "<p></p>";
  const document = new DOMParser().parseFromString(html, "text/html");
  const [value, setValue] = useState(deserialize(document.body));

  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  let fontColor = "";

  return (
    <Slate editor={editor} value={value}>
      <Editable
        placeholder="Start typing here..."
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        spellCheck
        autoFocus
        readOnly={props.disabled}
        style={{
          color: fontColor,
          padding: "10px",
          // minHeight: "400px", // ความสูงขั้นต่ำ 400px
          // height: "auto", // ปรับความสูงตามเนื้อหา
          // overflowY: "auto",
        }}
      />
    </Slate>
  );
};

const Element = ({ attributes, children, element }) => {
  const style = element.alignment ? { textAlign: element.alignment } : {};
  switch (element.type) {
    case "bulleted-list":
      return (
        <ul {...attributes} style={style}>
          {children}
        </ul>
      );
    case "heading-one":
      return (
        <h1
          {...attributes}
          style={
            element.alignment
              ? { textAlign: element.alignment, fontSize: 24 }
              : {}
          }
        >
          {children}
        </h1>
      );
    case "list-item":
      return (
        <li {...attributes} style={style}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol {...attributes} style={style}>
          {children}
        </ol>
      );
    case "image":
      return (
        <>
          <div
            {...attributes}
            style={{
              display: "flex",
              justifyContent: element.alignment || "center",
              marginBottom: 10,
            }}
          >
            <img
              className="slate-editor-img"
              src={element.url}
              alt=""
              style={{
                width: element.width || "300px",
                height: element.height || "auto",
              }}
              draggable="true"
            />
          </div>
        </>
      );

    default:
      return (
        <p {...attributes} style={style}>
          {children}
        </p>
      );
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const serialize = (node) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }

    if (node.italic) {
      string = `<em>${string}</em>`;
    }

    if (node.underline) {
      string = `<u>${string}</u>`;
    }

    return string;
  }

  const children = node.children.map((n) => serialize(n)).join("");
  const alignmentStyle = node.alignment
    ? ` style="text-align:${node.alignment};"`
    : "";

  let list = "";
  switch (node.type) {
    case "paragraph":
      return `<p${alignmentStyle}>${children}</p>`;
    case "numbered-list":
      node.children
        .map((n) => serialize(n))
        .map((a) => (list += `<li>${a}</li>`));
      return `<ol${alignmentStyle}>${list}</ol>`;
    case "heading-one":
      return `<h1${alignmentStyle}>${children}</h1>`;
    case "bulleted-list":
      node.children
        .map((n) => serialize(n))
        .map((a) => (list += `<li>${a}</li>`));
      return `<ul${alignmentStyle}>${list}</ul>`;
    case "image":
      return `<img class="slate-editor-img" src="${escapeHtml(
        node.url
      )}" style="width:${node.width || "300px"}; height:${node.height ||
        "auto"}; text-align:"center"};" />`;

    default:
      return children;
  }
};

const deserialize = (el) => {
  if (el.nodeType === 3) {
    // If text node
    return el.textContent;
  } else if (el.nodeType !== 1) {
    // If not an element node
    return null;
  }

  let children = Array.from(el.childNodes).map(deserialize);

  if (children.length === 0) {
    children = [{ text: "" }];
  }

  // Extract text-align style if present
  const style = el.getAttribute("style");
  let alignment = null;
  if (style) {
    const styleObj = style.split(";").reduce((acc, rule) => {
      const [key, value] = rule.split(":").map((item) => item.trim());
      if (key && value) {
        acc[key] = value;
      }
      return acc;
    }, {});

    alignment = styleObj["text-align"] || null;
  }

  switch (el.nodeName) {
    case "BODY":
      return jsx("fragment", {}, children);
    case "BR":
      return "\n";
    case "STRONG":
      return jsx("text", { bold: true }, children);
    case "P":
      return jsx("element", { type: "paragraph", alignment }, children);
    case "OL":
      return jsx("element", { type: "numbered-list", alignment }, [
        children.map((x) => jsx("element", { type: "list-item" }, [x])),
      ]);
    case "EM":
      return jsx("text", { italic: true }, children);
    case "U":
      return jsx("text", { underline: true }, children);
    case "H1":
      return jsx("element", { type: "heading-one", alignment }, children);
    case "UL":
      return jsx("element", { type: "bulleted-list", alignment }, [
        children.map((x) => jsx("element", { type: "list-item" }, [x])),
      ]);
    case "IMG":
      return jsx(
        "element",
        {
          type: "image",
          url: el.getAttribute("src"),
          width: el.style.width || "300px",
          height: el.style.height || "auto",
          alignment: "center",
        },
        [{ text: "" }] // Add a blank text node for proper rendering
      );

    default:
      return el.textContent;
  }
};

export default RichText;
