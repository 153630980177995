// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import AuthorizeComponent from '../../../auth/components/AuthorizeComponent'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import { Row, Col, Spin, PageHeader, Badge, Button, message } from 'antd'

// Components
import FormSearch from './FormSearch'
import TableLog from './TableLog'

// API
import {
  taxRenewalReadinessCompanyList,
  taxRenewalReadinessLog,
  taxRenewalReadinessCheckDlt,
  removeLists,
} from '../actions'

// CSS
import '../assets/css/index.css'

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      // *เช็คว่ามีการ filter หรือไม่
      isFilter: false,
      visible_form_search: false,
      canFilterCompany: false,

      // *filterObj
      filterObj: {
        FilterCompanyID: '',
        FilterVehicleCode: '',
        FilterPlateNo: '',
        FilterDeviceCode: '',
      },

      pagination: {
        page: 1,
        pageSize: 50,
      },

      // *ข้อมูลในตาราง table_log
      // เก็บ array ของวันที่และเดือนที่เลือก
      log_format_data: [],

      // columns
      table_columns: [],

      // ข้อมูล table log
      log_detail: [],
      total_log_detail: 0,
    }
  }

  componentDidMount() {
    let company_id = this.props.auth.profile.company_id
    if (company_id == 1 || company_id == 2) {
      // ให้ freewill fx และ test company ดูได้ทุกบริษัท
      this.setState(
        {
          canFilterCompany: true,
        },
        () => {
          this.fetchCompanyList()

          //*first search
          this.handleSearch({
            company_id: company_id,
            vehicle_code: '',
            plate_no: '',
            device_code: '',
          })
        }
      )
    } else {
      //*first search
      this.handleSearch({
        company_id: company_id,
        vehicle_code: '',
        plate_no: '',
        device_code: '',
      })
    }
  }

  componentWillUnmount() {
    this.onRemoveLists()
  }

  onRemoveLists = () => {
    this.props.removeLists()
  }

  //*เมื่อมีการเปลี่ยนแปลง state
  shouldComponentUpdate(nextProps, nextState) {
    // *log
    if (
      this.props.taxRenewalReadiness.check_log !==
      nextProps.taxRenewalReadiness.check_log
    ) {
      if (Object.keys(nextProps.taxRenewalReadiness.log_list).length > 0) {
        let temp_data = nextProps.taxRenewalReadiness.log_list.data || []
        let temp_total = nextProps.taxRenewalReadiness.log_list.total || 0

        // เพิ่ม key ชื่อ loading = false ให้กับข้อมูลใน temp_data
        temp_data = temp_data.map((item) => {
          return {
            ...item,
            loading: false,
          }
        })

        this.setState(
          {
            log_detail: temp_data,
            total_log_detail: temp_total,
          },
          () => {}
        )
      }
    }

    // *check dlt
    if (
      this.props.taxRenewalReadiness.check_dlt !==
      nextProps.taxRenewalReadiness.check_dlt
    ) {
      if (Object.keys(nextProps.taxRenewalReadiness.dlt_list).length > 0) {
        let dlt_data = nextProps.taxRenewalReadiness.dlt_list.data || []
        let status = nextProps.taxRenewalReadiness.dlt_list.status
        let msg = nextProps.taxRenewalReadiness.dlt_list.msg

        // ให้แทนที่ข้อมูลเดิมของรถ ใน log_detail ด้วยข้อมูลที่ได้จากการ check dlt
        let temp_log_detail = this.state.log_detail
        // แทนที่เฉพาะข้อมูลที่ตรงกัน
        let temp_data = temp_log_detail.map((item) => {
          let temp = dlt_data.find(
            (item2) => item2.vehicle_id == item.vehicle_id
          )
          if (temp) {
            item.terminus_gps_datetime = temp.terminus_gps_datetime
            item.terminus_license_scan_id = temp.terminus_license_scan_id
            item.terminus_license_scan_datetime =
              temp.terminus_license_scan_datetime
            item.dlt_gps_datetime = temp.dlt_gps_datetime
            item.dlt_driving_license_id = temp.dlt_driving_license_id
            item.dlt_checked_at = temp.dlt_checked_at
            item.loading = false
          }
          return item
        })

        this.setState(
          {
            log_detail: temp_data,
          },
          () => {
            if (status == 'success') {
              this.messageSuccess(msg)
            } else {
              // แสดง error message
              this.messageError("ไม่สามารถเชื่อมต่อข้อมูลกับกรมขนส่งได้ กรุณาติดต่อ Admin TERMINUS")
            }
          }
        )
      }
    }

    return true
  }

  // *ดึงข้อมูล company
  fetchCompanyList = () => {
    this.props.taxRenewalReadinessCompanyList({
      accessToken: this.props.auth.accessToken,
    })
  }

  // *ดึงข้อมูล table log
  fetchTableLogData = async () => {
    const { filterObj, pagination, canFilterCompany } = this.state

    // *fetch data table log
    this.props.taxRenewalReadinessLog({
      ...pagination,
      filterObj,
      vehicleVisibility: this.props.auth.profile.vehicle_visibility,
      canFilterCompany: canFilterCompany,
      accessToken: this.props.auth.accessToken,
    })
  }

  // *ค้นหา
  handleSearch = (values) => {
    const { canFilterCompany } = this.state
    let isFilter = false
    if (values.vehicle_code || values.plate_no || values.device_code) {
      isFilter = true
    }

    if (canFilterCompany) {
      if (values.company_id) {
        isFilter = true
      }
    }

    this.setState(
      {
        filterObj: {
          FilterCompanyID: values.company_id,
          FilterVehicleCode: values.vehicle_code,
          FilterPlateNo: values.plate_no,
          FilterDeviceCode: values.device_code,
        },

        pagination: {
          ...this.state.pagination,
          page: 1,
        },
        isFilter,

        visible_form_search: false,
      },
      () => {
        // *fetch data table log
        this.fetchTableLogData()
      }
    )
  }

  // *เปิด form search
  handleOpenFormSearch = () => {
    this.setState({ visible_form_search: true })
  }

  // *ปิด form search
  handleCloseFormSearch = () => {
    this.setState({ visible_form_search: false })
  }

  handleChangePage = (page, pageSize) => {
    this.setState(
      {
        pagination: {
          page,
          pageSize,
        },
      },
      () => {
        this.fetchTableLogData()
      }
    )
  }

  // *check dlt
  handleCheckDLT = (record) => {
    // set loading ให้กับ record ที่กด check dlt
    let { log_detail } = this.state
    let temp_data = log_detail.map((item) => {
      if (item.vehicle_id == record.vehicle_id) {
        return {
          ...item,
          loading: true,
        }
      }
      return item
    })

    this.setState(
      {
        log_detail: temp_data,
      },
      () => {
        this.props.taxRenewalReadinessCheckDlt({
          vehicle_id: record.vehicle_id,
          accessToken: this.props.auth.accessToken,
        })
      }
    )
  }

  // *แจ้งเตือน success
  messageSuccess = (msg) => {
    message.success(msg)
  }

  // *แจ้งเตือน error
  messageError = (msg) => {
    message.error(msg)
  }

  render() {
    const {
      isFilter,
      visible_form_search,
      canFilterCompany,

      log_detail,
      table_columns,
      pagination,
      total_log_detail,
    } = this.state

    const { check_log } = this.props.taxRenewalReadiness

    return (
      <AuthorizeComponent {...this.props} matching_name='taxRenewalReadiness'>
        <GeneralStyledContent>
          <div className='tax-renewal-readiness'>
            {/* header */}
            <PageHeader
              style={{ paddingLeft: '0px' }}
              title={
                <div className='header__text'>
                  {i18n[this.props.language].t.taxRenewalReadiness}
                </div>
              }
            />

            {/* ปุ่มค้นหาแถบบน */}
            <Row type='flex' style={{ marginBottom: '10px' }}>
              <Col span={24} align='right'>
                <Badge dot={isFilter} align='right'>
                  <Button
                    icon='filter'
                    size='small'
                    align='right'
                    onClick={this.handleOpenFormSearch}
                  >
                    <span>{i18n[this.props.language].s.search}</span>
                  </Button>
                </Badge>
              </Col>
            </Row>

            {/* table log */}
            <Row>
              <Spin
                spinning={check_log}
                tip={`${i18n[this.props.language].l.loading}...`}
              >
                <TableLog
                  data={log_detail || []}
                  column={table_columns}
                  pagi={pagination}
                  total={total_log_detail}
                  handleChangePage={this.handleChangePage}
                  handleCheckDLT={this.handleCheckDLT}
                />
              </Spin>
            </Row>

            {/* form search */}
            <FormSearch
              handleSearch={this.handleSearch}
              visible={visible_form_search}
              handleClose={this.handleCloseFormSearch}
              canFilterCompany={canFilterCompany}
            />
          </div>
        </GeneralStyledContent>
      </AuthorizeComponent>
    )
  }
}

export default connect(
  ({ taxRenewalReadiness, ui: { language }, auth, auth: { profile } }) => ({
    taxRenewalReadiness,
    language,
    auth,
    profile,
  }),
  {
    taxRenewalReadinessCompanyList: taxRenewalReadinessCompanyList.request,
    taxRenewalReadinessLog: taxRenewalReadinessLog.request,
    taxRenewalReadinessCheckDlt: taxRenewalReadinessCheckDlt.request,
    removeLists,
  }
)(Index)
