import React, { useState, useEffect } from "react";
import FormManuals from "./FormManuals";
import { Spin } from "antd";
import { getManualByID, updateManuals } from "../fetchApi/apiClient";

const ManualEdit = (props) => {
  const [initialValues, setInitialValues] = useState(null); // ใช้ state เพื่อเก็บค่า initialValues
  useEffect(() => {
    getManualByID(props.match.params.id, (status, res) => {
      const data = {
        titleName: res.data.data.topic,
        page: res.data.data.m_permission_id,
        link: res.data.data.manual_link,
        isPublish: res.data.data.is_publish,
      };
      setInitialValues(data); // อัปเดตค่า state
    });
  }, [props.match.params.id]);

  if (!initialValues) {
    return (
      <Spin spinning={true}>
        <FormManuals
          initialValues={{
            titleName: undefined,
            page: undefined,
            link: undefined,
            isPublish: false,
          }}
          readOnly={false}
          onSubmit={(val) => {}}
        />
      </Spin>
    ); // แสดงข้อความระหว่างรอข้อมูลจาก API
  }

  return (
    <FormManuals
      initialValues={initialValues} // ใช้ค่าจาก state
      readOnly={false}
      onSubmit={(val) => {
        updateManuals(
          props.match.params.id,
          {
            topic: val.titleName,
            manual_link: val.link,
            is_publish: val.link,
            m_permission_id: val.page,
          },
          (status, res) => {
            window.alert("แก้ไขสำเร็จ");
            window.location.replace("/news-manuals");
          }
        );
      }}
    />
  );
};

export default ManualEdit;
