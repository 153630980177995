import React from "react";
import { Switch, Route } from "react-router-dom";
import Index from "./Index";
import Detail from "./Detail";

export default () => (
  <Switch>
    <Route path="/dashboard-adas" component={Index} exact />
    <Route
      path="/dashboard-adas/detail/:vehicle_id/:duration_id/:hardware_id/:typeId"
      component={Detail}
    />
  </Switch>
);
