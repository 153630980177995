import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import {
  Row,
  Col,
  Layout,
  Menu,
  Icon,
  Modal,
  AutoComplete,
  Input,
  Select,
  Button,
} from "antd";
import { ReactComponent as ManualsIcon } from "./Manual.svg";

// import NotificationReceiver from "./NotificationReceiver";
import CustomBadge from "./CustomBadge";
import Notification from "./Notification";
import LangContext, { i18n } from "modules/shared/context/langContext";
import * as authActions from "modules/auth/actions";
import {
  clearFlashMessage,
  loadNotificationCount,
  unregisterNotificate,
  removeUIState,
} from "modules/ui/actions";
import { messaging } from "../../../lib/fcm/fcm_init";

import styled from "styled-components";

import axios from "axios";
import { dataSource } from "./constant";
import { getURLByMatchingName } from "../fetchAPI/fetchAPI";
import { el } from "date-fns/locale";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const { Header: H } = Layout;
const StyledHearder = styled(H)`
  background: white;
  padding: 0px 0px px 0px;
  height: 45px;
`;

const { Option, OptGroup } = AutoComplete;

class Header extends Component {
  state = {
    notiCount: 0,
    haveNewNoti: false,
    inputLng: "",
    token: "",
    searchText: "",
    menu: "",
    url: null,
  };

  _isMounted = false;

  onRemoveCredentials = () => {
    if (typeof this.props.profile !== "undefined") {
      this.updateUserHistoryLog();
    }
    this.props.unregisterNotificate({
      username: this.props.profile.username,
    });
    this.props.removeCredentials();
    this.props.removeUIState();
  };

  updateUserHistoryLog = () => {
    axios
      .post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/updatelogonhistory`,
        {
          id: this.props.profile.user_history_logon_id,
        },
        {
          headers: { Authorization: `Bearer ${this.props.accessToken}` },
        }
      )
      .then((res) => {
        console.log("update logon history success");
      });
  };

  onDismiss = () => {
    this.props.clearFlashMessage();
  };

  componentDidMount() {
    this._isMounted = true;
    let self = this;
    let { tokenExp } = this.props;
    let timeNow = Date.now();

    if (typeof tokenExp == "undefined" || tokenExp == null || tokenExp == "") {
      setTimeout(function() {
        self.modal(
          <LangContext.Consumer>
            {(i18n) => i18n.n.notification}
          </LangContext.Consumer>,
          <LangContext.Consumer>
            {(i18n) => i18n.t.tokenExpired}
          </LangContext.Consumer>
        );
      }, 500);
    }
    if (Math.floor(timeNow / 1000) < tokenExp) {
      let diffTime = (tokenExp - Math.floor(timeNow / 1000)) * 1000;
      this.tokenExpTimeout = setTimeout(function() {
        self.modal(
          <LangContext.Consumer>
            {(i18n) => i18n.n.notification}
          </LangContext.Consumer>,
          <LangContext.Consumer>
            {(i18n) => i18n.t.tokenExpired}
          </LangContext.Consumer>
        );
      }, diffTime);
    }

    this.setState({
      inputLng: "th",
    });

    if (messaging) {
      // รับ Token Notification
      messaging
        .getToken()
        .then((token) => {
          this.setToken(token);
        })
        .catch((err) => {
          console.log("Unable to retrieve refreshed token ", err);
        });
    }

    const result = this.findPath(dataSource, window.location.pathname);
    if (result != null) {
      getURLByMatchingName(
        { match_name: result.matching_name },
        (status, res) => {
          if (res.data.data == null) {
            this.setState({ url: null });
          } else {
            this.setState({ url: res.data.data.manual_link });
          }
        }
      );
    } else {
      this.setState({ url: null });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.headerUrl != this.props.headerUrl) {
      return true;
    }

    if (
      prevState.url != this.state.url ||
      prevProps.location.pathname != window.location.pathname
    ) {
      const result = this.findPath(dataSource, window.location.pathname);
      if (result != null) {
        getURLByMatchingName(
          { match_name: result.matching_name },
          (status, res) => {
            if (res.data.data == null) {
              this.setState({ url: null });
            } else {
              this.setState({ url: res.data.data.manual_link });
            }
          }
        );
      } else {
        this.setState({ url: null });
      }
    }
  }

  findPath = (data, searchPath) => {
    for (const item of data) {
      // Check if the current item's path matches the searchPath
      if (item.path === searchPath) {
        return item;
      }
      // If the item has children, search recursively
      if (item.children) {
        const result = this.findPath(item.children, searchPath);
        if (result) {
          return result;
        }
      }
    }
    return null; // Return null if no match is found
  };

  setToken(token) {
    this.setState({
      ...this.state,
      token,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.tokenExpTimeout);
  }

  checkSelectLang = (inputLng) => {
    this.props.changeLang(inputLng);
    this.props.storeLang(inputLng);
    if (inputLng == "en") {
      this.setState({
        inputLng: "us",
      });
    } else {
      this.setState({
        inputLng: "th",
      });
    }
  };

  modal = (title, content) => {
    this.onRemoveCredentials();
    Modal.warning({
      title: title,
      okText: "Ok",
      okType: "default",
      onOk() {
        // window.location.href = "/";
      },
      content: content,
    });
  };

  handleSearch = (value) => {
    this.setState({ searchText: value });
  };

  render() {
    const {
      accessToken,
      done,
      flash,
      collapsed,
      toggle,
      profile,
      language,
    } = this.props;
    const { permissions } = this.props.profile;

    function filterByLabel(data, input) {
      const result = [];

      data.forEach((item) => {
        const label =
          typeof item.label == "string"
            ? item.label
            : item.label(i18n[language]) || ".";

        if (
          typeof label == "string" &&
          label.toLowerCase().includes(input.toLowerCase()) &&
          permissions.some(
            (p) => p.can_view && p.matching_name === item.matching_name
          )
        ) {
          result.push(item);
        } else if (item.children) {
          const filteredChildren = filterByLabel(item.children, input);
          if (filteredChildren.length) {
            result.push({ ...item, children: filteredChildren });
          }
        }
      });

      return result;
    }

    const getLabel = (child) =>
      typeof child.label == "string"
        ? child.label
        : child.label(i18n[language]) || ".";

    const getChild = (childs) => {
      const result = [];

      childs.forEach((group) =>
        group.children
          ? result.push(
              <OptGroup key={getLabel(group)} label={getLabel(group)}>
                {group.children.map((item) => {
                  const res = [];
                  if (item.children) {
                    res.push(
                      <OptGroup key={getLabel(item)} label={getLabel(item)}>
                        {item.children.map((children) => (
                          <Option
                            key={getLabel(children)}
                            value={children.path}
                          >
                            {getLabel(children)}
                          </Option>
                        ))}
                      </OptGroup>
                    );
                  } else {
                    res.push(
                      <Option key={getLabel(item)} value={item.path}>
                        {getLabel(item)}
                      </Option>
                    );
                  }
                  return res;
                })}
              </OptGroup>
            )
          : result.push(
              <Option key={getLabel(group)} value={group.path}>
                {getLabel(group)}
              </Option>
            )
      );

      return result;
    };

    const options = getChild(filterByLabel(dataSource, this.state.searchText));

    const CheLan = (
      <LangContext.Consumer>
        {(i18n) => i18n.changeLanguage}
      </LangContext.Consumer>
    );

    return (
      <Fragment>
        <StyledHearder>
          <audio id="audio-alert">
            <source src="/audio/alert/serious-strike.mp3" type="audio/mpeg" />
          </audio>
          <audio id="audio-sos">
            <source src="/audio/alert/sos.mp3" type="audio/mpeg" />
          </audio>
          <audio id="audio-notification">
            <source
              src="/audio/notification/slow-spring-board.mp3"
              type="audio/mpeg"
            />
          </audio>
          {/* <NotificationReceiver /> */}
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            style={{
              lineHeight: "20px",
              padding: "0px 0px 0px 0px",
              margin: "0px 0px 0px 0px",
            }}
          >
            <Col xs={{ span: 0 }} sm={8} md={4} lg={4} xl={4}>
              <Icon
                style={{ marginTop: "3px" }}
                className="trigger"
                type={collapsed ? "menu-unfold" : "menu-fold"}
                onClick={toggle}
              />
            </Col>

            <Col sm={8} md={8} lg={8} xl={8}>
              <AutoComplete
                size="large"
                style={{ width: "100%" }}
                dataSource={options}
                value={this.state.menu}
                placeholder={i18n[language].s.searchMenu}
                optionLabelProp="value"
                onSearch={this.handleSearch}
                onChange={(value) => this.setState({ menu: value })}
                onSelect={(value) => {
                  this.props.history.push(value);
                  this.setState({ menu: "" });
                }}
              >
                <Input
                  suffix={
                    <Icon type="search" className="certain-category-icon" />
                  }
                />
              </AutoComplete>
            </Col>
            <Col sm={4} md={4} lg={4} xl={4} align="right">
              {this.state.url != null ? (
                <div>
                  <Button
                    type="primary"
                    onClick={() =>
                      window.open(this.state.url, "_blank").focus()
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <ManualsIcon />
                  </Button>
                </div>
              ) : null}
            </Col>

            <Col sm={4} md={8} lg={8} xl={8} align="right">
              <Menu
                theme="light"
                mode="horizontal"
                style={{ height: "46px" }}
                align="right"
              >
                <SubMenu
                  title={
                    <span>
                      <CustomBadge
                        companyID={profile.company_id}
                        userID={profile.id}
                        mqttData={this.props.data}
                        // notiCount={this.props.notiCount}
                        loadNotificationCount={this.props.loadNotificationCount}
                        // onNotiCountChange={this.onNotiCountChange}
                        //onHaveNewNotiChange={this.onHaveNewNotiChange}
                      >
                        <Notification
                          history={this.props.history}
                          lastestNotificationAt={
                            this.props.lastestNotificationAt
                          }
                          // haveNewNoti={this.state.haveNewNoti}
                          // newNoti={this.props.data}
                          // notificationCount={this.props.notificationCount}
                          // onNotiCountChange={this.onNotiCountChange}
                          //onHaveNewNotiChange={this.onHaveNewNotiChange}
                        />
                      </CustomBadge>
                    </span>
                  }
                ></SubMenu>

                <SubMenu
                  title={
                    <span>
                      <ReactCountryFlag
                        styleProps={{
                          margin: "0px 15px 5px 0px",
                          width: "20px",
                          height: "20px",
                        }}
                        code={this.state.inputLng}
                        svg
                      />
                      <Icon type="caret-down" />
                    </span>
                  }
                >
                  <MenuItemGroup title={CheLan}>
                    <Menu.Item key="en" selected={true}>
                      <span
                        className="nav-text"
                        onClick={() => this.checkSelectLang("en")}
                      >
                        <ReactCountryFlag
                          styleProps={{
                            margin: "0px 15px 5px 0px",
                            width: "20px",
                            height: "20px",
                          }}
                          code="us"
                          svg
                        />
                        English
                      </span>
                    </Menu.Item>
                    <Menu.Item key="th" selected={true}>
                      <span
                        className="nav-text"
                        onClick={() => this.checkSelectLang("th")}
                      >
                        <ReactCountryFlag
                          styleProps={{
                            margin: "0px 15px 5px 0px",
                            width: "20px",
                            height: "20px",
                          }}
                          code="th"
                          svg
                        />
                        ไทย
                      </span>
                    </Menu.Item>
                  </MenuItemGroup>
                </SubMenu>
                <SubMenu
                  title={
                    <div>
                      <Row>
                        {" "}
                        <Col xs={24} sm={0} md={0} lg={0} xl={0}>
                          <Icon type="user" />
                        </Col>{" "}
                      </Row>
                      <Row>
                        <Col xs={0} sm={24} md={24} lg={24} xl={24}>
                          <Icon type="user" />
                          {JSON.parse(localStorage.getItem("profile")).username}
                        </Col>
                      </Row>
                    </div>
                  }
                >
                  <MenuItemGroup
                    title={
                      <LangContext.Consumer>
                        {(i18n) => i18n.t.titleAccount}
                      </LangContext.Consumer>
                    }
                  >
                    <Menu.Item key="setting:1" style={{ paddingLeft: "14px" }}>
                      <Icon type="home" />
                      <LangContext.Consumer>
                        {(i18n) => i18n.h.home}
                      </LangContext.Consumer>
                      <Link to="/" />
                    </Menu.Item>
                    <Menu.Item key="setting:2" style={{ paddingLeft: "14px" }}>
                      <Icon type="user" />
                      <LangContext.Consumer>
                        {(i18n) => i18n.p.profile}
                      </LangContext.Consumer>
                      <Link to="/profile" />
                    </Menu.Item>
                    {done ? (
                      <Menu.Item
                        key="menu:1"
                        style={{ paddingLeft: "14px" }}
                        onClick={this.onRemoveCredentials}
                      >
                        <Icon type="logout" />
                        <LangContext.Consumer>
                          {(i18n) => i18n.l.logout}
                        </LangContext.Consumer>
                        <Link to="/" />
                      </Menu.Item>
                    ) : (
                      <Menu.Item key="menu:1">
                        <LangContext.Consumer>
                          {(i18n) => i18n.l.login}
                        </LangContext.Consumer>
                        <Link to="/" />
                      </Menu.Item>
                    )}
                  </MenuItemGroup>
                  {/* <MenuItemGroup title="Setting">
                    <Menu.Item key="setting:3">Option 3</Menu.Item>
                    <Menu.Item key="setting:4">Option 4</Menu.Item>
                  </MenuItemGroup> */}
                </SubMenu>
              </Menu>
            </Col>
          </Row>
        </StyledHearder>
        <Row type="flex" justify="end" style={{ margin: "0px" }} />
        {flash && (
          <Row type="flex" justify="center" style={{ margin: "0px" }}>
            {/* <Col span={24}>
              <Alert
                message={flash.message}
                type={flash.type}
                closable
                afterClose={this.handleClose}
              />
            </Col> */}
          </Row>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  auth: { accessToken, done, profile, tokenExp },
  ui: { flash, lastestNotificationAt, language },
}) => ({
  accessToken,
  done,
  profile,
  flash,
  lastestNotificationAt,
  tokenExp,
  language,
});
const mapDispatchToProps = {
  removeCredentials: authActions.removeCredentials,
  removeUIState: removeUIState,
  loadCredentials: authActions.loadCredentials,
  clearFlashMessage: clearFlashMessage,
  loadNotificationCount: loadNotificationCount.request,
  unregisterNotificate: unregisterNotificate.request,
  storeLang: authActions.changeLang,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
