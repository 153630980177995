import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Menu, Icon } from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { StyledSider, StyledImageHeader } from "../../../styled/common-styled";
import { AutorizeMenu } from "../../shared/components/CustomMenu";

import { autoGenKey } from "../../../lib/helper";

class SliderBar extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  render() {
    const { collapsed } = this.props;

    const { permissions } = this.props.profile;

    return (
      <StyledSider trigger={null} collapsible collapsed={collapsed}>
        <Link to="/">
          <StyledImageHeader>
            <img style={{ width: "85px" }} src="/img/logo2.png" alt="" />
          </StyledImageHeader>
        </Link>

        <Menu theme="dark" mode="inline" inlineIndent={0}>
          {/* ระบบติดตาม */}
          <AutorizeMenu
            permissions={permissions}
            title={
              <span>
                <Icon type="eye" />
                <span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.m.monitoring}
                  </LangContext.Consumer>
                </span>
              </span>
            }
          >
            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              className="display-menu"
              matching_name="tracking_dashboard"
            >
              <Link to="/tracking">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.t.tracking}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="replay"
              className="display-menu"
            >
              <Link to="/replay">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.r.replay}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="incident_dashboard"
              className="display-menu"
            >
              <Link to="/incident-dashboard">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.i.incident}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.SubMenu
              popupClassName="popup-menu-custom scroll-style"
              key={autoGenKey(`sub-menu`)}
              permissions={permissions}
              title={
                <LangContext.Consumer>
                  {(i18n) => i18n.l.liveStream}
                </LangContext.Consumer>
              }
            >
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="honeytoast_livestream"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/livestream">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.l.liveStream}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="video_mdvr"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/video">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.v.video}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="video_mdvr_special"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/videospecial">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.v.videospecial}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              popupClassName="popup-menu-custom scroll-style"
              key={autoGenKey(`sub-menu`)}
              permissions={permissions}
              title={
                <LangContext.Consumer>
                  {(i18n) => i18n.v.videoDownload}
                </LangContext.Consumer>
              }
            >
              <Menu.Item
                className={collapsed ? "" : "menu-item-wrap"}
                key={autoGenKey("slider-sub-menu")}
                matching_name="download_video_honeytoast"
              >
                <Link to="/downloadvideoqueue">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.h.honeytoastdownloadfile}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                className={collapsed ? "" : "menu-item-wrap"}
                key={autoGenKey("slider-sub-menu")}
                matching_name="download_video_mdvr"
              >
                <Link to="/mdvrdownloadfile">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.mdvrdownloadfile}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                className={collapsed ? "" : "menu-item-wrap"}
                key={autoGenKey("slider-sub-menu")}
                matching_name="playback_video_honeytoast"
              >
                <Link to="/honeytoastplayback">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.h.honeytoastplayback}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.Item
              className={collapsed ? "" : "menu-item-wrap"}
              key={autoGenKey("slider-sub-menu")}
              matching_name="boat_dashboard"
            >
              <Link to="/boat_delivery">
                <Icon type="filter" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.b.boatDeliveryDashboard}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              className="display-menu"
              matching_name="dash_camera_dashboard"
            >
              <Link to="/dash_cameras">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.dashCamera}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              className="display-menu"
              key={autoGenKey("slider-sub-menu")}
              matching_name="booking_overview_dashboard"
            >
              <Link to="/booking_overview">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.b.bookingOverview}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              className="display-menu"
              key={autoGenKey("slider-sub-menu")}
              matching_name="cashing_overview_dahsboar"
            >
              <Link to="/cashing_overview">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.e.earningOverview}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            {/* cpf dashboard */}
            <Menu.Item
              className="display-menu"
              key={autoGenKey("slider-sub-menu")}
              matching_name="cpfdashboard"
            >
              <Link to="/cpf-dashboard">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.cpfDashboard}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>
          </AutorizeMenu>
          {/* จบ ระบบติดตาม */}

          {/* แดชบอร์ด */}
          <AutorizeMenu
            permissions={permissions}
            title={
              <span>
                <Icon type="dashboard" />
                <span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.dashboard}
                  </LangContext.Consumer>
                </span>
              </span>
            }
          >
            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="concrete_dashboard"
              className="display-menu"
            >
              <Link to="/concrete_delivery">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.concreteDeliveryDashboard}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              className="display-menu"
              key={autoGenKey("slider-sub-menu")}
              matching_name="multi_drop_dashboard"
            >
              <Link to="/multi_drop">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => "Multi Drop"}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              className={collapsed ? "" : "menu-item-wrap"}
              key={autoGenKey("slider-sub-menu")}
              matching_name="alert_alarm_dashboard"
            >
              <Link to="/alert_alarm">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.a.alertAlarm}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              className="display-menu"
              key={autoGenKey("slider-sub-menu")}
              matching_name="event_map_dashboard"
            >
              <Link to="/event_map">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.e.eventMap}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              className={collapsed ? "" : "menu-item-wrap"}
              key={autoGenKey("slider-sub-menu")}
              matching_name="skic-inhouse"
            >
              <Link to="/skic-inhouse">
                <span className="nav-text">แดชบอร์ด Skic inhouse logistic</span>
              </Link>
            </Menu.Item>
            <Menu.Item
              className={collapsed ? "" : "menu-item-wrap"}
              key={autoGenKey("slider-sub-menu")}
              matching_name="driver-dashboard"
            >
              <Link to="/driver-dashboard">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.driverDashboard}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            {/* <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="cameraStatusDashboard"
            >
              <Link to="/camera-status-dashboard">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.cameraStatusDashboard}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item> */}
            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="monitorGPS"
              className="display-menu"
            >
              <Link to="/monitor-gps">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.m.monitorGPS}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="dashboardAdasDms"
            >
              <Link to="/dashboard-adas">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.dashboardAdasDmsMenu}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>
          </AutorizeMenu>
          {/* จบ แดชบอร์ด */}

          {/* งานจอง */}
          <AutorizeMenu
            permissions={permissions}
            title={
              <span>
                <Icon type="table" />
                <span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.b.bookingPool}
                  </LangContext.Consumer>
                </span>
              </span>
            }
          >
            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="booking_pool"
              className="display-menu"
            >
              <Link to="/center_pool">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.b.booking}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="finished_pool"
              className="display-menu"
            >
              <Link to="/finished_pool">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.f.finished}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="cancelled_pool"
              className="display-menu"
            >
              <Link to="/cancelled_pool">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.cancelled}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>
          </AutorizeMenu>
          {/* จบ งานจอง */}

          {/* รายงาน */}
          <AutorizeMenu
            permissions={permissions}
            title={
              <span>
                <Icon type="profile" />
                <span>
                  {" "}
                  <LangContext.Consumer>
                    {(i18n) => i18n.r.report}
                  </LangContext.Consumer>
                </span>
              </span>
            }
          >
            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="schedulereport"
              className="display-menu"
            >
              <Link to="/schedulereport">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.s.schedulereport}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="graph_sensor"
              className="display-menu"
            >
              <Link to="/graphsensors">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.g.graphsonsors}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="performancelog"
              className="display-menu"
            >
              <Link to="/performancelog">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.p.performanceLog}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.SubMenu
              popupClassName="popup-menu-custom scroll-style"
              key={autoGenKey(`sub-menu`)}
              permissions={permissions}
              title={
                <LangContext.Consumer>
                  {(i18n) => i18n.b.behavior_report_menu}
                </LangContext.Consumer>
              }
            >
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_alarmalertbyplateno"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/alarmalertbyplateno">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) =>
                        i18n.a
                          .alarmAndAlertReportClassifiedByDailyCarRegistration
                      }
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_alarmalertbydriver"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/alarmalertbydriver">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.DailyAlarmAndAlertReportByDriver}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_alarmalertfrequencybydriver"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/alarmalertfrequencybydriver">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) =>
                        i18n.r.reportAlarmFrequencyClassifiedByDailyDriver
                      }
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_alarmalertbydurationtime"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/alarmalertbydurationtime">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) =>
                        i18n.t
                          .top5AlertReportsOfEachTypeDividedByMonthlyTimePeriod
                      }
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_drivingbehavior"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/drivingbehavior">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.dailyDrivingBehaviorReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_drivinglicensescanbehavior"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/drivinglicensescanbehavior">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) =>
                        i18n.r.reportCardSwipeBehaviorBeforeDrivingDaily
                      }
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_alarmalertbyplatenoonyear"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/alarmalertbyplatenoonyear">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) =>
                        i18n.a
                          .alarmAndAlertReportClassifiedByYearlyCarRegistration
                      }
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_separatevehiclestatus"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/separatevehiclestatus">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.dailyCarStatusReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_alertdriverlicense"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/alertdriverlicense">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.t.theDriverIsIicenseReportHasExpired}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_parkingareaoutside"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/parkingareaoutside">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) =>
                        i18n.r.reportSummarizingTheNumberOfOffSiteParking
                      }
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_drivinglog"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/drivinglog">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.DrivinglogReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_drivinglogspecial"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/drivinglogspecial">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.DrivinglogspecialReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_summary_drivinglog"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/summarydrivinglog">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.s.summaryDrivingLogReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_speedoverlimitsummary"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/speedoverlimitsummary">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.s.SpeedoverlimitsummaryReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_summarykpi"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/summarykpi">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.s.SummaryKPIReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_summarykpibydriver"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/summarykpibydriver">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.s.SummaryKPIByDriverReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_riskbehaviour"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/riskbehaviour">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.r.RiskbehaviourReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_vehicleinarea"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/vehicleinarea">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.v.VehicleinareaReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_inoutlocation"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/inoutlocation">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.i.inoutlocationreport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_drivinglicensescanlog"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/drivinglicensescanlog">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.DrivinglicensescanlogReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_drivinglicenseexpire"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/drivinglicenseexpire">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.DrivingLicenseExpireReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_drivinghour"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/drivinghour">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.DrivinghourReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="speedOverLimit"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/speedoverlimit">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.s.speedOverLimit}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_drivingcontinuous"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/drivingcontinuous">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.drivingcontinuous}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_notinotswipecard"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/notinotswipecard">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.n.notinotswipecard}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_incident"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/incident">
                  <LangContext.Consumer>
                    {(i18n) => i18n.i.incidentReport}
                  </LangContext.Consumer>
                </Link>
              </Menu.Item>
              <Menu.Item
                className={collapsed ? "" : "menu-item-wrap"}
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_flow"
              >
                <Link to="/report/flow">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.r.reportFlow}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_adas_event"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/adasevent">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.a.AdasEventReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_secbysec"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/secbysec">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.s.secbysecReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              popupClassName="popup-menu-custom scroll-style"
              key={autoGenKey(`sub-menu`)}
              permissions={permissions}
              title={
                <LangContext.Consumer>
                  {(i18n) => i18n.g.general_report_menu}
                </LangContext.Consumer>
              }
            >
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_truckcurrentlocation"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/truckcurrentlocation">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.t.TruckcurrentlocationReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_driverinformation"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/driverinformation">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.DriverinformationReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_numberofdaysworked"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/numberofdaysworked">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.n.NumberofdaysworkedReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_distfromlastmaintenance"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/distfromlastmaintenance">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.DistfromlastmaintenanceReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_maintenance"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/maintenance">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.m.MaintenanceReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_temperature"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/temperature">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.t.TemperatureReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_drivingdistance"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/drivingdistance">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.DrivingdistanceReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_event"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/event">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.e.EventReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_truckusage"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/truckusage">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.t.TruckusageReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_truckusage_sjc_monthly"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/monthlytruckusages">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.t.TruckusageSJCMonthlyReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_parking"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/parking">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.p.ParkingReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_comparevehiclescco"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/comparevehiclescco">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.c.CompareVehicleSccoReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_drivingoverlimit"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/drivingoverlimit">
                  <span className="nav-text">
                    {" "}
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.DrivingoverlimitReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_dozeoffrisk"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/dozeoffrisk">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.DozeoffriskReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              {/* <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_loginhistory"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/loginhistory">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.l.LoginhistoryReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item> */}

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_gpsstatus"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/gpsstatus">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.g.GpsstatusReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_tripbyengineoffon"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/tripbyengineoffon">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.t.TripbyengineoffonReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="userlogonhistory"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/userlogonhistory">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.u.userLogonReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_dailyiconsiam"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/dailyiconsiam">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.DailyiconsiamReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_summaryiconsiam"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/summaryiconsiam">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.s.SummaryiconsiamReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_fuelremainrate"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/fuelremainrate">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.f.fuelremainrateReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_fuelnotify"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/fuelnotify">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.f.fuelNotifyReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_summarylinenotification"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/summarylinenotification">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.r.report_summarylinenotification}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_driverincome"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/driverincome">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.driverincome}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              popupClassName="popup-menu-custom scroll-style"
              key={autoGenKey(`sub-menu`)}
              permissions={permissions}
              title={
                <LangContext.Consumer>
                  {(i18n) => i18n.e.efficiency_report_menu}
                </LangContext.Consumer>
              }
            >
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_inputactivity"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/inputactivity">
                  <span className="nav-text">
                    {" "}
                    <LangContext.Consumer>
                      {(i18n) => i18n.i.InputactivityReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_trucknotuse"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/trucknotuse">
                  <span className="nav-text">
                    {" "}
                    <LangContext.Consumer>
                      {(i18n) => i18n.t.TrucknotuseReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_truckengineoff"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/truckengineoff">
                  <span className="nav-text">
                    {" "}
                    <LangContext.Consumer>
                      {(i18n) => i18n.t.TruckengineoffReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_truckengineon"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/truckengineon">
                  <span className="nav-text">
                    {" "}
                    <LangContext.Consumer>
                      {(i18n) => i18n.t.TruckengineonReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_velocitysummary"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/velocitysummary">
                  <span className="nav-text">
                    {" "}
                    <LangContext.Consumer>
                      {(i18n) => i18n.v.VelocitysummaryReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_vehicleperformanceconclude"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/vehicleperformanceconclude">
                  <span className="nav-text">
                    {" "}
                    <LangContext.Consumer>
                      {(i18n) => i18n.v.VehicleperformanceconcludeReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_gpslostcontact"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/gpslostcontact">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.r.report_gpsLostContact}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_video_snapshot"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/video-snapshot-report">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.v.videoSnapshotReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              popupClassName="popup-menu-custom scroll-style"
              key={autoGenKey(`sub-menu`)}
              permissions={permissions}
              title={
                <LangContext.Consumer>
                  {(i18n) => i18n.t.trip_report_menu}
                </LangContext.Consumer>
              }
            >
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_tripsumbylocation"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/tripsumbylocation">
                  <span className="nav-text">
                    {" "}
                    <LangContext.Consumer>
                      {(i18n) => i18n.t.TripsumbylocationReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_tripsummary"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/tripsummary">
                  <span className="nav-text">
                    {" "}
                    <LangContext.Consumer>
                      {(i18n) => i18n.t.TripsummaryReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_daily"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/daily">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.DailyReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_tripsumbyveh"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/tripsumbyveh">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.t.TripsumbyvehReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_triptemperature"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/triptemperature">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.t.TriptemperatureReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_delivery"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/delivery">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.DeliveryReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_boatdelivery"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/boatdelivery">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.b.boatDeliveryReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              {/* รายงานเส้นทางรับส่งพนักงานของคฑาทอง */}
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_pickupanddropoffemployee"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/pickup-dropoff-employee">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.p.pickupAndDropoffEmployee}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              {/* รายงานแจ้งเตือนรถเข้าพื้นที่ */}
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_vehicleentryalert"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/vehicle-entry-alert">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.v.vehicleEntryAlert}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_rmcconcretetrip"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/report/rmcconcretetrip">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.r.rmcconcretetrip}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              popupClassName="popup-menu-custom scroll-style"
              key={autoGenKey(`sub-menu`)}
              permissions={permissions}
              title={
                <LangContext.Consumer>
                  {(i18n) => i18n.e.energy_report_menu}
                </LangContext.Consumer>
              }
            >
              <Menu.Item
                className={collapsed ? "" : "menu-item-wrap"}
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_refuel"
              >
                <Link to="/report/refuel">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.r.RefuelReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                className={collapsed ? "" : "menu-item-wrap"}
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_fuelfull"
              >
                <Link to="/report/fuelfull">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.f.fuelFull}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                className={collapsed ? "" : "menu-item-wrap"}
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_withdrawfuelcredit"
              >
                <Link to="/report/withdrawfuelcredit">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.r.reportWithdrawfuelcredit}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                className={collapsed ? "" : "menu-item-wrap"}
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_depositfuelcredit"
              >
                <Link to="/report/depositfuelcredit">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.r.reportDepositfuelcredit}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              popupClassName="popup-menu-custom scroll-style"
              key={autoGenKey(`sub-menu`)}
              permissions={permissions}
              title={
                <LangContext.Consumer>
                  {(i18n) => i18n.m.maintenancedevice_report_menu}
                </LangContext.Consumer>
              }
            >
              <Menu.Item
                className={collapsed ? "" : "menu-item-wrap"}
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_maintenancedeviceconclude"
              >
                <Link to="/report/maintenancedeviceconclude">
                  <span className="nav-text">
                    {" "}
                    <LangContext.Consumer>
                      {(i18n) => i18n.m.MaintenancedeviceconcludeReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                className={collapsed ? "" : "menu-item-wrap"}
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_vehicleinformationdlt"
              >
                <Link to="/report/vehicleinformationdlt">
                  <span className="nav-text">
                    {" "}
                    <LangContext.Consumer>
                      {(i18n) => i18n.v.VehicleinformationdltReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              popupClassName="popup-menu-custom scroll-style"
              key={autoGenKey(`sub-menu`)}
              permissions={permissions}
              title={
                <LangContext.Consumer>
                  {(i18n) => i18n.i.insure_report_menu}
                </LangContext.Consumer>
              }
            >
              <Menu.Item
                className={collapsed ? "" : "menu-item-wrap"}
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_insurance"
              >
                <Link to="/report/insurance">
                  <span className="nav-text">
                    {" "}
                    <LangContext.Consumer>
                      {(i18n) => i18n.i.InsuranceReport}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              popupClassName="popup-menu-custom scroll-style"
              key={autoGenKey(`sub-menu`)}
              permissions={permissions}
              title="Taxi"
              // title={
              //   <LangContext.Consumer>
              //     {(i18n) => i18n.i.insure_report_menu}
              //   </LangContext.Consumer>
              // }
            >
              <Menu.Item
                className={collapsed ? "" : "menu-item-wrap"}
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_taxifare"
              >
                <Link to="/report/taxifare">
                  <span className="nav-text">
                    {" "}
                    <LangContext.Consumer>
                      {(i18n) => i18n.r.report_taxifare}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item
                className={collapsed ? "" : "menu-item-wrap"}
                key={autoGenKey("slider-sub-menu")}
                matching_name="report_taxisos"
              >
                <Link to="/report/taxisos">
                  <span className="nav-text">
                    {" "}
                    <LangContext.Consumer>
                      {(i18n) => i18n.r.report_taxisos}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
          </AutorizeMenu>
          {/* จบรายงาน */}

          {/* ข้อมูลหลัก */}
          <AutorizeMenu
            permissions={permissions}
            title={
              <span>
                <Icon type="folder" />
                <span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.m.masterData}
                  </LangContext.Consumer>
                </span>
              </span>
            }
          >
            <Menu.SubMenu
              key={autoGenKey(`sub-menu`)}
              permissions={permissions}
              title={
                <LangContext.Consumer>
                  {(i18n) => i18n.v.vehicleAndDriver}
                </LangContext.Consumer>
              }
              // className={collapsed ? "" : "menu-item-wrap"}
            >
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="vehicle"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/vehicle">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.v.vehicle}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="vehiclegroup"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/vehiclegroup">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.v.vehicleGroup}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="deviceuser"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/deviceuser">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.c.chauffeur}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="parttype"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/parttype">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.p.partType}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="partgroup"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/partgroup">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.p.partgroup}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="refuel"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/refuel">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.r.reFuelTitle}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="maintenance"
                className="display-menu"
              >
                <Link to="/maintenance">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.m.maintenance}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              popupClassName="popup-menu-custom scroll-style"
              key={autoGenKey(`sub-menu`)}
              permissions={permissions}
              title={
                <LangContext.Consumer>
                  {(i18n) => i18n.l.locationAndRoute}
                </LangContext.Consumer>
              }
            >
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="location"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/location">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.l.Location}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="locationgroup"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/locationgroup">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.l.locationGroup}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="location-type"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/location-type">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.l.location_type}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="nearbylocation"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/nearbylocation">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.n.nearByLocation}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="zone"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/zone">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.z.zone}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="route"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/route">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.r.route}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="route_iconsiam"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/route-iconsiam">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.r.route_iconsiam}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="trip_duration"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/trip-duration">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.t.trip_duration_management}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="customer"
              className="display-menu"
            >
              <Link to="/customer">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.customer}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="pull_vehicle_sink"
              className="display-menu"
            >
              <Link to="/pullvehiclesink">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.p.pullvehiclesink}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="sendemail"
              className="display-menu"
            >
              <Link to="/verifyemail">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.a.accountVerification}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="uom"
              className="display-menu"
            >
              <Link to="/uom">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.u.uom}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="product"
              className="display-menu"
            >
              <Link to="/product">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.p.product}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="newsManual"
              className="display-menu"
            >
              <Link to="/news-manuals">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.newsManuals.news}
                  </LangContext.Consumer>{" "}
                  <LangContext.Consumer>
                    {(i18n) => i18n.newsManuals.manuals}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>
          </AutorizeMenu>
          {/* จบ ข้อมูลหลัก */}

          {/* การจัดการ */}
          <AutorizeMenu
            permissions={permissions}
            title={
              <span>
                <Icon type="folder-open" />
                <span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.m.management_menu}
                  </LangContext.Consumer>
                </span>
              </span>
            }
          >
            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="user"
              className="display-menu"
            >
              <Link to="/user">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.u.user}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="usergroup"
              className="display-menu"
            >
              <Link to="/usergroup">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.u.userGroup}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="datavisibility"
              className="display-menu"
            >
              <Link to="/datavisibility">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.dataVisibility}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="permission"
              className="display-menu"
            >
              <Link to="/permission">
                <span className="nav-text">Permission</span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="partner"
              className="display-menu"
            >
              <Link to="/partner">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.p.partnerCar}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="vehicle_sharing"
              className="display-menu"
            >
              <Link to="/vehicle_sharing">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.v.vehicleSharing}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="sharelocation"
              className="display-menu"
            >
              <Link to="/sharelocation">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.s.shareLocation}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="gallery"
              className="display-menu"
            >
              <Link to="/gallery">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.g.gallery}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="management_contactmanagement"
              className="display-menu"
            >
              <Link to="/management/contactmanagement">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.Contactmanagement}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="manageotp"
              className="display-menu"
            >
              <Link to="/manage-otp">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.manageOTP.manageOTP}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            {/* <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="manage-dashboard-monitoring"
              className={collapsed ? "" : "menu-item-wrap"}
            >
              <Link to="/manage-dashboard-monitoring">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) =>
                      i18n.manageDashboardMonitoring.manageDashboardMonitoring
                    }
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item> */}

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="managefuelrate"
              className="display-menu"
            >
              <Link to="/manage-fuelrate">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.manageFuelRate.manageFuelRate}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="manage_document2"
              className={collapsed ? "" : "menu-item-wrap"}
            >
              <Link to="/manage-document2">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.i.insuranceAndTaxes}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="managedepositfuelcredit"
              className="display-menu"
            >
              <Link to="/deposit-fuel-credit">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.m.manageDepositFuelCredit}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="managewithdrawfuelcredit"
              className="display-menu"
            >
              <Link to="/withdraw-fuel-credit">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.m.manageWithdrawFuelCredit}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="fuelcredit-setting"
              className="display-menu"
            >
              <Link to="/fuelcredit-setting">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.orc.configFuelcredit}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="outofcontact"
              className="display-menu"
            >
              <Link to="/outofcontact">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.o.outofcontact}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="management_downloadcertificate"
              className="display-menu"
            >
              <Link to="/management/downloadcertificate">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.downloadcertificate}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="temporarytracking"
              className="display-menu"
            >
              <Link to="/temporarytracking">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.t.temporaryTracking}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>
          </AutorizeMenu>
          {/* จบ การจดการ */}

          {/* การจัดกการภายใน */}
          <AutorizeMenu
            permissions={permissions}
            title={
              <span>
                <Icon type="audit" />
                <span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.i.internalOperation}
                  </LangContext.Consumer>
                </span>
              </span>
            }
          >
            <Menu.SubMenu
              popupClassName="popup-menu-custom scroll-style"
              key={autoGenKey(`sub-menu`)}
              permissions={permissions}
              title={
                <LangContext.Consumer>
                  {(i18n) => i18n.d.deliveryItem}
                </LangContext.Consumer>
              }
            >
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="deliveryitem"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/deliveryitem">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.deliveryItemPool}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="deliveryitem_delete_history"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/deliveryitem_delete_history">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.d.deliveryItemDeleteHistoryPool}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              popupClassName="popup-menu-custom scroll-style"
              key={autoGenKey("slider-sub-menu")}
              permissions={permissions}
              title={
                <LangContext.Consumer>
                  {(i18n) => i18n.g.gpsCertification}
                </LangContext.Consumer>
              }
            >
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                matching_name="gps_certification_request"
                className={collapsed ? "" : "menu-item-wrap"}
              >
                <Link to="/gps_certification_request">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.g.gpsCertRequest}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                className={collapsed ? "" : "menu-item-wrap"}
                matching_name="gps_certification_request_history"
              >
                <Link to="/gps_certification_request_history">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.g.gpsCertRequestHistory}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              popupClassName="popup-menu-custom scroll-style"
              key={autoGenKey("slider-sub-menu")}
              permissions={permissions}
              title={
                <LangContext.Consumer>
                  {(i18n) => i18n.a.announcement}
                </LangContext.Consumer>
              }
            >
              <Menu.Item
                key={autoGenKey("slider-sub-menu")}
                className={collapsed ? "" : "menu-item-wrap"}
                matching_name="announcement_broadcast"
              >
                <Link to="/announcement_broadcast">
                  <span className="nav-text">
                    <LangContext.Consumer>
                      {(i18n) => i18n.a.announcementBroadcast}
                    </LangContext.Consumer>
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="company"
              className="display-menu"
            >
              <Link to="/company">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.company}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="create_frimware"
              className="display-menu"
            >
              <Link to="/firmware">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.createFrimware}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="assign_frimware"
              className="display-menu"
            >
              <Link to="/assignfirmware">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.a.assignFirmware}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>
          </AutorizeMenu>
          {/* จบ การจัดกการภายใน */}

          {/* การขนส่ง */}
          <AutorizeMenu
            permissions={permissions}
            title={
              <span>
                <Icon type="retweet" />
                <span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.deliver}
                  </LangContext.Consumer>
                </span>
              </span>
            }
          >
            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="trip"
              className="display-menu"
            >
              <Link to="/trip">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.t.trip}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="delivery_trip"
              className="display-menu"
            >
              <Link to="/deliverytrip">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.d.deliverytrip}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="route_master"
              className="display-menu"
            >
              <Link to="/routemaster">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.r.routemaster}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>
          </AutorizeMenu>
          {/* จบการขนส่ง */}

          {/* ช่วยเหลือ */}
          <AutorizeMenu
            permissions={permissions}
            title={
              <span>
                <Icon type="question" />
                <span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.h.help_menu}
                  </LangContext.Consumer>
                </span>
              </span>
            }
          >
            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="servicerepairdevice"
              className="display-menu"
            >
              <Link to="/servicerepairdevice">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.s.Servicerepairdevice}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="servicemovedevice"
              className="display-menu"
            >
              <Link to="/servicemovedevice">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.m.moveDevice}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            {/* <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="serviceinstalldevice"
            >
              <Link to="/serviceinstalldevice">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {i18n => i18n.i.installDevice}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item> */}

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              className="display-menu"
              matching_name="manage_repairdevice"
            >
              <Link to="/manage-repairdevice">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.m.manageRepairDevice}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="monitorSDCard"
              className="display-menu"
            >
              <Link to="/monitor-sdcard">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.m.monitorSDCard}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="taxRenewalReadiness"
              className="display-menu"
            >
              <Link to="/tax-renewal-readiness">
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.t.taxRenewalReadiness}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>
          </AutorizeMenu>
          {/* จบการช่วยเหลือ */}

          {/* ตั้งค่า */}
          <AutorizeMenu
            permissions={permissions}
            title={
              <span>
                <Icon type="setting" />
                <span>
                  <LangContext.Consumer>
                    {(i18n) => i18n.s.setting}
                  </LangContext.Consumer>
                </span>
              </span>
            }
          >
            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              className="display-menu"
              matching_name="policy"
            >
              <Link to="/policy">
                <Icon type="read" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.p.policy}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              className="display-menu"
              matching_name="calibratefuel"
            >
              <Link to="/calibratefuel">
                <Icon type="read" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.calibratefuel}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={autoGenKey("slider-sub-menu")}
              matching_name="companysetting"
              className="display-menu"
            >
              <Link to="/companysetting">
                <Icon type="read" />
                <span className="nav-text">
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.companysetting}
                  </LangContext.Consumer>
                </span>
              </Link>
            </Menu.Item>
          </AutorizeMenu>
          {/* จบการตั้งค่า */}
        </Menu>
      </StyledSider>
    );
  }
}

const mapStateToProps = ({ auth: { profile } }) => ({
  profile,
});

export default connect(mapStateToProps, null)(SliderBar);
