import axios from "axios";

const axiosClientFromWeb = axios.create({
  baseURL: `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("access-token")}`,
  },
});

// Centralized error handling function (example)
const handleError = (error, callback) => {
  // Handle error here or pass it to callback

  console.error("API Error:", error);
  //   callback(error.response?.status, error.response?.data);
};

export async function getURLByMatchingName(params, callback) {
  try {
    const res = await axiosClientFromWeb.get("/manual/matching-name", {
      params,
    });
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

export default axiosClientFromWeb;
