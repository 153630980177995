import React, { useEffect, useState } from "react";
import FormNews from "./FormNews";
import { Description } from "./mockup";
import { editNews, getNewsById } from "../fetchApi/apiClient";
import { Spin } from "antd";

const NewsEdit = (props) => {
  const [initialValues, setInitialValues] = useState(null); // ใช้ state เพื่อเก็บค่า initialValues

  useEffect(() => {
    getNewsById(props.match.params.id, (status, res) => {
      const data = {
        titleName: res.data.data.topic,
        newsCatagory: res.data.data.news_category_id.toString(),
        content: res.data.data.detail,
        isPublish: res.data.data.is_publish,
      };
      setInitialValues(data); // อัปเดตค่า state
    });
  }, [props.match.params.id]);

  if (!initialValues) {
    return (
      <Spin spinning={true}>
        <FormNews
          initialValues={{
            titleName: "",
            newsCatagory: "",
            content: "",
            isPublish: false,
          }} // ใช้ค่าจาก state
          readOnly={false}
          onSubmit={(val) => {}}
        />
      </Spin>
    ); // แสดงข้อความระหว่างรอข้อมูลจาก API
  }

  return (
    <FormNews
      initialValues={initialValues} // ใช้ค่าจาก state
      readOnly={false}
      onSubmit={(val) => {
        editNews(
          props.match.params.id,
          {
            topic: val.titleName,
            news_category_id: val.newsCatagory,
            detail: val.content,
            is_publish: val.isPublish,
          },
          (status, res) => {
            window.alert("แก้ไขสำเร็จ");
            window.location.replace("/news-manuals");
          }
        );
      }}
    />
  );
};

export default NewsEdit;
