import React, { Component } from 'react'
import { connect } from 'react-redux'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import {
  Button,
  Row,
  Col,
  Form,
  DatePicker,
  Input,
  Drawer,
  Tooltip,
  Select,
} from 'antd'
import { handleFilter } from 'lib/helper'

const { Option } = Select
const FormItem = Form.Item
const { RangePicker } = DatePicker
const { TextArea } = Input

class FormSearch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initialValues: {
        company_id: this.props.auth.profile.company_id,
        vehicle_code: '',
        plate_no: '',
        device_code: '',
      },
    }
  }

  render() {
    let { initialValues } = this.state
    let { handleSearch, visible, handleClose, canFilterCompany } = this.props
    const { company_list } = this.props.taxRenewalReadiness

    return (
      <Drawer
        title={i18n[this.props.language].s.search}
        width={500}
        placement='right'
        onClose={handleClose}
        maskClosable={true}
        visible={visible}
        className='tax-renewal-readiness-drawer-component'
      >
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={yup.object().shape({})}
          onSubmit={(values) => {
            // ส่งค่าให้กับ function เพื่อค้นหาข้อมูล
            handleSearch(values)
          }}
          ref={(formik) => (this.formik = formik)}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleFocus,
            setFieldValue,
            resetForm,
          }) => (
            <>
              <Form onSubmit={handleSubmit}>
                <>
                  {/* start บริษัท */}
                  {canFilterCompany && (
                    <Row>
                      <Col span={24}>
                        <FormItem
                          validateStatus={
                            touched.company_id && errors.company_id && 'error'
                          }
                          help={touched.company_id && errors.company_id}
                          label={
                            <span className='label-font-style'>
                              {i18n[this.props.language].c.company}
                            </span>
                          }
                        >
                          <div>
                            <Select
                              name='company_id'
                              className='select-style'
                              showSearch
                              optionFilterProp='title'
                              onChange={(value) => {
                                setFieldValue('company_id', value)
                              }}
                              placeholder={i18n[this.props.language].c.company}
                              value={values.company_id}
                            >
                              <Option value={null} title='-'>
                                <span className='option-style'>-</span>
                              </Option>

                              {company_list.map((item, index) => {
                                return (
                                  <Option
                                    key={index}
                                    value={item.company_id}
                                    title={item.company_name}
                                  >
                                    <Tooltip
                                      placement='rightTop'
                                      title={`${item.company_name}`}
                                    >
                                      <span className='option-style'>
                                        {`${item.company_name}`}
                                      </span>
                                    </Tooltip>
                                  </Option>
                                )
                              })}
                            </Select>
                          </div>
                        </FormItem>
                      </Col>
                    </Row>
                  )}

                  {/* end บริษัท */}

                  {/* start รหัสรถ */}
                  <Row>
                    <Col span={24}>
                      <FormItem
                        validateStatus={
                          touched.vehicle_code && errors.vehicle_code && 'error'
                        }
                        help={touched.vehicle_code && errors.vehicle_code}
                        label={
                          <span className='label-font-style'>
                            {i18n[this.props.language].v.vehicleCode}
                          </span>
                        }
                      >
                        <div>
                          <TextArea
                            name='vehicle_code'
                            value={values.vehicle_code}
                            onChange={(e) => {
                              let vehicle_code = handleFilter(e.target.value);
                              setFieldValue('vehicle_code', vehicle_code)
                            }}
                          />
                        </div>
                      </FormItem>
                    </Col>
                  </Row>
                  {/* end รหัสรถ */}

                  {/* start ทะเบียนรถ*/}
                  <Row>
                    <Col span={24}>
                      <FormItem
                        validateStatus={
                          touched.plate_no && errors.plate_no && 'error'
                        }
                        help={touched.plate_no && errors.plate_no}
                        label={
                          <span className='label-font-style'>
                            {i18n[this.props.language].v.vehiclePlateNo}
                          </span>
                        }
                      >
                        <div>
                          <TextArea
                            name='plate_no'
                            value={values.plate_no}
                            onChange={(e) => {
                              let plate_no = handleFilter(e.target.value);
                              setFieldValue('plate_no', plate_no)
                            }}
                          />
                        </div>
                      </FormItem>
                    </Col>
                  </Row>
                  {/* end ทะเบียนรถ */}

                  {/* start device code*/}
                  <Row>
                    <Col span={24}>
                      <FormItem
                        validateStatus={
                          touched.device_code && errors.device_code && 'error'
                        }
                        help={touched.device_code && errors.device_code}
                        label={
                          <span className='label-font-style'>
                            {i18n[this.props.language].d.deviceCode}
                          </span>
                        }
                      >
                        <div>
                          <TextArea
                            name='device_code'
                            value={values.device_code}
                            onChange={(e) => {
                              let device_code = handleFilter(e.target.value);
                              setFieldValue('device_code', device_code)
                            }}
                          />
                        </div>
                      </FormItem>
                    </Col>
                  </Row>
                  {/* end device code */}

                  {/* start search button */}
                  <Row>
                    <Col span={24} align='right'>
                      <FormItem>
                        <div>
                          <Button
                            type='button'
                            onClick={() => {
                              handleClose()
                            }}
                            className='button-search-style'
                          >
                            <span>{i18n[this.props.language].c.cancel}</span>
                          </Button>
                          <Button
                            type='primary'
                            htmlType='submit'
                            className='button-search-style'
                          >
                            <span>{i18n[this.props.language].a.apply}</span>
                          </Button>
                        </div>
                      </FormItem>
                    </Col>
                  </Row>
                </>
                {/* end search button */}
              </Form>
            </>
          )}
        />
      </Drawer>
    )
  }
}

export default connect(
  ({ taxRenewalReadiness, auth, auth: { profile }, ui: { language } }) => ({
    taxRenewalReadiness,
    auth,
    profile,
    language,
  }),
  {}
)(FormSearch)
